const pattern = `
# gitignore template for Gretl
# website: http://gretl.sourceforge.net/

# Auto-generated log file is overwritten whenever you start a new session
session.inp

# Auto-generated temporary string code table
string_table.txt
`
export default pattern;
