const pattern = `
#--------------------------#
# Magento Default Files    #
#--------------------------#

/PATCH_*.sh

/app/etc/local.xml

/media/*
!/media/.htaccess

!/media/customer
/media/customer/*
!/media/customer/.htaccess

!/media/dhl
/media/dhl/*
!/media/dhl/logo.jpg

!/media/downloadable
/media/downloadable/*
!/media/downloadable/.htaccess

!/media/xmlconnect
/media/xmlconnect/*

!/media/xmlconnect/custom
/media/xmlconnect/custom/*
!/media/xmlconnect/custom/ok.gif

!/media/xmlconnect/original
/media/xmlconnect/original/*
!/media/xmlconnect/original/ok.gif

!/media/xmlconnect/system
/media/xmlconnect/system/*
!/media/xmlconnect/system/ok.gif

/var/*
!/var/.htaccess

!/var/package
/var/package/*
!/var/package/*.xml

`
export default pattern;
