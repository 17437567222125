const pattern = `
# Cache files, generates by Zephir
.temp/
.libs/

# Object files, generates by linker
*.lo
*.la
*.o
*.loT

# Files generated by configure and Zephir,
# not required for extension compilation.
ext/build/
ext/modules/
ext/Makefile*
ext/config*
ext/acinclude.m4
ext/aclocal.m4
ext/autom4te*
ext/install-sh
ext/ltmain.sh
ext/missing
ext/mkinstalldirs
ext/run-tests.php
ext/.deps
ext/libtool
`
export default pattern;
