const pattern = `
Mercury/
Mercury.modules
*.mh
*.err
*.init
*.dll
*.exe
*.a
*.so
*.dylib
*.beams
*.d
*.c_date
`
export default pattern;
