const pattern = `
# gitignore template for LogTalk, a programming language that builds upon Prolog
# website: https://logtalk.org/

# Logtalk temporary file directories
.lgt_tmp/
lgt_tmp/

# Logtalk default unit testing and doclet results and logs directories
logtalk_tester_logs/
logtalk_doclet_logs/

# backend Prolog compiler temporary files
.pl-history
*.out
*.xwam
*.qo
*.ql
*.itf
*.po
`
export default pattern;
