const pattern = `
# gitignore template for the Racket language
# website: http://www.racket-lang.org/

# DrRacket autosave files
*.rkt~
*.rkt.bak
\\#*.rkt#
\\#*.rkt#*#

# Compiled racket bytecode
compiled/
*.zo

# Dependency tracking files
*.dep
`
export default pattern;
