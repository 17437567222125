const pattern = `
# .gitignore file for git projects containing Stata files
# Commercial statistical software: http://www.stata.com

# Stata dataset and output files
*.dta
*.gph
*.log
*.smcl
*.stpr
*.stsem

# Graphic export files from Stata
# Stata command graph export: http://www.stata.com/manuals14/g-2graphexport.pdf
#
# You may add graphic export files to your .gitignore. However you should be
# aware that this will exclude all image files from this main directory
# and subdirectories.
# *.ps
# *.eps
# *.wmf
# *.emf
# *.pdf
# *.png
# *.tif
`
export default pattern;
