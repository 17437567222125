const pattern = `
# For projects using Nanoc (http://nanoc.ws/)

# Default location for output (needs to match output_dir's value found in nanoc.yaml)
output/

# Temporary file directory
tmp/nanoc/

# Crash Log
crash.log
`
export default pattern;
