const pattern = `
# Compiled Form Modules
*.fmx

# Compiled Menu Modules
*.mmx

# Compiled Pre-Linked Libraries
*.plx
`
export default pattern;
