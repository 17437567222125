const pattern = `
# Gitignore for Raku (https://raku.org)
# As part of https://github.com/github/gitignore

# precompiled files
.precomp
lib/.precomp

`
export default pattern;
