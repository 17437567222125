const pattern = `
*.pyc
*.pyo
*.tmp*
*.mo
*.egg
*.EGG
*.egg-info
*.EGG-INFO
.*.cfg
bin/
build/
develop-eggs/
downloads/
eggs/
fake-eggs/
parts/
dist/
var/
`
export default pattern;
