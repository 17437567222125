const pattern = `
*.exe
*.o
*.so
*.tmp.c
*.exp
*.ilk
*.pdb
*.dll
*.lib
*.bak
*.out
`
export default pattern;
