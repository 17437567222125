const pattern = `
*.py[co]

# Default development database
devdata.db

# Default data directory
data/*

# Packages
*.egg
*.egg-info
dist
build

# Installer logs
pip-log.txt

# Unit test / coverage reports
.coverage
.tox
`
export default pattern;
