import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GridTile from '../components/gridcard.js';

export default function Home() {
  return(
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_publish_post_vowb.svg"
              link="https://makeall.dev"
              title="makeall.dev"
              subtitle="Coding blog" >
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_game_world_0o6q.svg"
              link="https://knoetical.com"
              title="knoetical"
              subtitle="makeall launchpad">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_Internet_on_the_go_re_vben.svg"
              link="https://play.google.com/store/apps/details?id=com.knoetical.smalltalk"
              title="QuickSurf"
              subtitle="Best of Internet in a Jiffy">
            </GridTile>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_cooking_lyxy.svg"
              link="https://play.google.com/store/apps/details?id=com.knoetical.recipegrab"
              title="RecipeGrab"
              subtitle="Grab and Browse Recipes Offline">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_Optimize_image_re_3tb1.svg"
              link="/imageconversion"
              title="Image Conversion Tool"
              subtitle="Image Conversion Too">
            </GridTile>
          </Col>          
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_Code_typing_re_p8b9.svg"
              link="/gitignoregen"
              title=".gitignore Generator"
              subtitle=".gitignore Generator">
            </GridTile>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_Contemplating_re_ynec.svg"
              link="/vanilla/cgol"
              title="Conway's GoL"
              subtitle="Written in PixiJS">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_text_files_au1q.svg"
              link="/vanilla/texteditor"
              title="TextEditor"
              subtitle="HTML text editor">
            </GridTile>
          </Col>          
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_solution_mindset_34bi.svg"
              link="/vanilla/npuzzle"
              title="nPuzzle"
              subtitle="overloaded 15-puzzle game">
            </GridTile>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_playing_cards_cywn.svg"
              link="/vanilla/countcards"
              title="Card Counting"
              subtitle="Simulated card counting">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
           <GridTile
              image="/assets/undraw_Code_thinking_re_gka2.svg"
              link="/vanilla/turingmachine"
              title="Turing Machine"
              subtitle="Editor and Compiler">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_Calculator_0evy.svg"
              link="/vanilla/optioncalculator"
              title="Options Calculator"
              subtitle="Simple and Reactive">
            </GridTile>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_Preparation_re_t0ce.svg"
              link="/vanilla/techstackgen"
              title="Tech Stack Generator"
              subtitle="Beyond web and mobile dev">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_old_day_6x25.svg"
              link="https://ranuzz.github.io/"
              title="ranuzz"
              subtitle="Text Adventure Game">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_feedback_h2ft.svg"
              link="http://gleaner.in"
              title="gleaner"
              subtitle="Instant user feedback">
            </GridTile>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_online_articles_re_yrkj.svg"
              link="https://selfzine-worker.makeall.workers.dev/"
              title="SelfZine"
              subtitle="A newsletter for your todos">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_share_link_qtxe.svg"
              link="https://pypi.org/project/getsome/"
              title="GetSome"
              subtitle="An interesting link generator CLI tool">
            </GridTile>
          </Col>
          <Col lg={4} md={4}>
            <GridTile
              image="/assets/undraw_mind_map_re_nlb6.svg"
              link="/vanilla/dnddomtree"
              title="DnD DOM Tree"
              subtitle="HTML5 DnD API and DOM Tree">
            </GridTile>
          </Col>
        </Row>
      </Container>
    </>
  );
}
