import React from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    selectSections,
    addSection,
    deleteSection,
    editSection,
    updateSection,
    previewOutput,
    selectEditorData,
    downloadOutput
} from './GenerateGitignoreSlice';
import gitignoreSections from './gitignoreSections';
import debugLog from '../../services/debug/log';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Editor from "@monaco-editor/react";
import { VscChromeClose, VscPreview, VscCloudDownload, VscEdit } from "react-icons/vsc";
import ReactAutocomplete from 'react-autocomplete';

class SearchComponentInput extends React.Component {

    constructor (props) {
      super(props)
      this.state = {
        value: '',
      }
    }
  

    onSelect(value) {
        Object.keys(gitignoreSections).map(sec => 
            Object.keys(gitignoreSections[sec]).map(subsec => 
                (value === subsec) ? 
                    this.props.dispatch(addSection({
                        "name": subsec,
                        "content": gitignoreSections[sec][subsec]
                    })) : null
            )
        );
        this.setState({ value });
    }

    render() {

    let items = [];
    Object.keys(gitignoreSections["Everything"]).map(subsec =>
            items.push({
                "id": subsec,
                "label": subsec
            })
        );
        
      return (
        <ReactAutocomplete
          items={items}
          shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
          getItemValue={item => item.label}
          renderItem={(item, highlighted) =>
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? '#eee' : 'transparent', zIndex: 100}}
            >
              {item.label}
            </div>
          }
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          onSelect={value => this.onSelect(value)}
          inputProps={{
              "size": "100",
              "type": "text",
              "class": "form-control"
          }}
        />
      )
    }
}

let SearchComponentInputContainer = connect()(SearchComponentInput);

function TheToolbar() {

    const dispatch = useDispatch();

    return (
        <>
        <Form>
            <Row>
                <Col xs={10}>
                    <SearchComponentInputContainer></SearchComponentInputContainer>
                </Col>

                <Col>
                    <ButtonGroup aria-label="Basic example">
                    <OverlayTrigger
                        key="preview-bottom"
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-preview-bottom">
                                Preview .gitignore in Editor
                            </Tooltip>
                        }>
                        <Button variant="outline-dark" size="md" onClick={() => dispatch(previewOutput())}><VscPreview /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        key="download-bottom"
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-download-bottom">
                                Download .gitignore iFile
                            </Tooltip>
                        }>
                        <Button variant="outline-dark" size="md" onClick={() => dispatch(downloadOutput())}><VscCloudDownload /></Button>
                    </OverlayTrigger>
                        
                    </ButtonGroup>
                </Col>
                <Col>
                
                </Col>
            </Row>
        </Form>
        </>
    );

}

function SelectedSections() {
    
    const dispatch = useDispatch();
    const sections = useSelector(selectSections);
    debugLog.debug("GitIgnoreSection :: sections :: %o", sections);

    return (
        <>
        <ul>
            {sections.map(item => 
                (<li key={"SelectedSectionsListGroupItem-" + item['id']} onClick={() => {}} style={{ margin: '5px'}}>
                    {item['data']['name']}<span>&nbsp;&nbsp;</span>
                    <Button
                        key={"SelectedSectionsButton-Edit-" + item['id']} 
                        onClick={() => dispatch(editSection(item))}
                        variant="outline-dark" size="sm">
                            <VscEdit />
                    </Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button
                        key={"SelectedSectionsButton-" + item['id']} 
                        onClick={() => dispatch(deleteSection(item['id']))}
                        variant="outline-dark" size="sm">
                            <VscChromeClose />
                    </Button>
                </li>)
            )}
        </ul>
        </>
    )
}

/*
        <ListGroup variant="flush">
            {sections.map(item => 
                (<ListGroup.Item key={"SelectedSectionsListGroupItem-" + item['id']} onClick={() => {}}>
                    {item['data']['name']}<span>&nbsp;&nbsp;</span>
                    <Button
                        key={"SelectedSectionsButton-Edit-" + item['id']} 
                        onClick={() => dispatch(editSection(item))}
                        variant="outline-dark" size="sm">
                            <VscEdit />
                    </Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button
                        key={"SelectedSectionsButton-" + item['id']} 
                        onClick={() => dispatch(deleteSection(item['id']))}
                        variant="outline-dark" size="sm">
                            <VscChromeClose />
                    </Button>
                </ListGroup.Item>)
            )}
        </ListGroup>
*/
function AvailableSections() {
    
    const dispatch = useDispatch();
    debugLog.debug("GitIgnoreSection :: gitignoreSections :: %o", gitignoreSections);

    return (
        <>
        {Object.keys(gitignoreSections).map(sec => {
            let labelKey = "AvailableSections-Label-".concat(sec.toString());
            return (
                <div key={labelKey}>
                    <label>{sec}</label><br/>
                    {Object.keys(gitignoreSections[sec]).map(subsec => {
                        let buttonKey = "AvailableSections-Button-".concat(sec.toString(), subsec.toString());
                        return (
                            <>
                            <Button
                                style={{ margin: '5px' }}
                                key={buttonKey}
                                variant="outline-dark" size="sm"
                                onClick={() => dispatch(addSection({
                                    "name": subsec,
                                    "content": gitignoreSections[sec][subsec]
                                }))}>
                                {subsec}
                            </Button>
                            </>
                        )

                    })}
                    <hr />
                </div>
            );
        })}
        </>
    );
}

export function GitIgnoreSection() {
    return (
        <>
            <br />
            <p>Search RuleSets</p>
            <TheToolbar></TheToolbar>
            <br />
            <p>Selected RuleSets</p>
            <SelectedSections></SelectedSections>
            <br />
            <p>Available RuleSets</p>
            <AvailableSections></AvailableSections>
        </>
    );
}

export function GitIgnoreEditor() {

    const dispatch = useDispatch();
    const sectionData = useSelector(selectEditorData);

    debugLog.debug(sectionData.toString());

    function handleEditorChange(value, event) {
        debugLog.debug("here is the current model value:", value);
        dispatch(updateSection(value));
    }

    let options = {
        "lineNumbers": "off"
    }
    return (
        <>
           <Editor
                height="90vh"
                defaultLanguage="markdown"
                theme="vs-dark"
                defaultValue="# select a section"
                onChange={handleEditorChange}
                options={options}
                value={sectionData}
            />
        </>
    );
}

export function GenerateGitignore() {
    return (
        <>
        <Container fluid>
            <Row>
                <Col lg={7}>
                    <GitIgnoreSection></GitIgnoreSection>
                </Col>
                <Col lg={5}>
                    <GitIgnoreEditor></GitIgnoreEditor>
                </Col>
            </Row>
        </Container>
        </>
    );
}