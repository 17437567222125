import Actionscript_gitignore from './Actionscript';
import Ada_gitignore from './Ada';
import Agda_gitignore from './Agda';
import AltiumDesigner_gitignore from './AltiumDesigner';
import Android_gitignore from './Android';
import Anjuta_gitignore from './Anjuta';
import Ansible_gitignore from './Ansible';
import AppceleratorTitanium_gitignore from './AppceleratorTitanium';
import AppEngine_gitignore from './AppEngine';
import Archives_gitignore from './Archives';
import ArchLinuxPackages_gitignore from './ArchLinuxPackages';
import AtmelStudio_gitignore from './AtmelStudio';
import Autotools_gitignore from './Autotools';
import Backup_gitignore from './Backup';
import Bazaar_gitignore from './Bazaar';
import Bazel_gitignore from './Bazel';
import Bitrix_gitignore from './Bitrix';
import BricxCC_gitignore from './BricxCC';
import C_gitignore from './C';
import CakePHP_gitignore from './CakePHP';
import Calabash_gitignore from './Calabash';
import CFWheels_gitignore from './CFWheels';
import ChefCookbook_gitignore from './ChefCookbook';
import Clojure_gitignore from './Clojure';
import Cloud9_gitignore from './Cloud9';
import CMake_gitignore from './CMake';
import CodeIgniter_gitignore from './CodeIgniter';
import CodeKit_gitignore from './CodeKit';
import CodeSniffer_gitignore from './CodeSniffer';
import CommonLisp_gitignore from './CommonLisp';
import Composer_gitignore from './Composer';
import Concrete5_gitignore from './Concrete5';
import Coq_gitignore from './Coq';
import Cordova_gitignore from './Cordova';
import Cpp_gitignore from './Cpp';
import CraftCMS_gitignore from './CraftCMS';
import CUDA_gitignore from './CUDA';
import CVS_gitignore from './CVS';
import D_gitignore from './D';
import Dart_gitignore from './Dart';
import DartEditor_gitignore from './DartEditor';
import Delphi_gitignore from './Delphi';
import Diff_gitignore from './Diff';
import DM_gitignore from './DM';
import Dreamweaver_gitignore from './Dreamweaver';
import Dropbox_gitignore from './Dropbox';
import Drupal_gitignore from './Drupal';
import Drupal7_gitignore from './Drupal7';
import Eagle_gitignore from './Eagle';
import Eclipse_gitignore from './Eclipse';
import EiffelStudio_gitignore from './EiffelStudio';
import Elisp_gitignore from './Elisp';
import Elixir_gitignore from './Elixir';
import Elm_gitignore from './Elm';
import Emacs_gitignore from './Emacs';
import Ensime_gitignore from './Ensime';
import EPiServer_gitignore from './EPiServer';
import Erlang_gitignore from './Erlang';
import Espresso_gitignore from './Espresso';
import esp_idf_gitignore from './esp_idf';
import Exercism_gitignore from './Exercism';
import ExpressionEngine_gitignore from './ExpressionEngine';
import ExtJs_gitignore from './ExtJs';
import Fancy_gitignore from './Fancy';
import Finale_gitignore from './Finale';
import FlexBuilder_gitignore from './FlexBuilder';
import ForceDotCom_gitignore from './ForceDotCom';
import Fortran_gitignore from './Fortran';
import FuelPHP_gitignore from './FuelPHP';
import Gcov_gitignore from './Gcov';
import GitBook_gitignore from './GitBook';
import GNOMEShellExtension_gitignore from './GNOMEShellExtension';
import Go_gitignore from './Go';
import Godot_gitignore from './Godot';
import GPG_gitignore from './GPG';
import Gradle_gitignore from './Gradle';
import Grails_gitignore from './Grails';
import Gretl_gitignore from './Gretl';
import GWT_gitignore from './GWT';
import Haskell_gitignore from './Haskell';
import Hugo_gitignore from './Hugo';
import IAR_EWARM_gitignore from './IAR_EWARM';
import Idris_gitignore from './Idris';
import IGORPro_gitignore from './IGORPro';
import Images_gitignore from './Images';
import InforCMS_gitignore from './InforCMS';
import Java_gitignore from './Java';
import JBoss_gitignore from './JBoss';
import JBoss4_gitignore from './JBoss4';
import JBoss6_gitignore from './JBoss6';
import JDeveloper_gitignore from './JDeveloper';
import Jekyll_gitignore from './Jekyll';
import JENKINS_HOME_gitignore from './JENKINS_HOME';
import JEnv_gitignore from './JEnv';
import JetBrains_gitignore from './JetBrains';
import Jigsaw_gitignore from './Jigsaw';
import Joomla_gitignore from './Joomla';
import Julia_gitignore from './Julia';
import JupyterNotebooks_gitignore from './JupyterNotebooks';
import Kate_gitignore from './Kate';
import KDevelop4_gitignore from './KDevelop4';
import Kentico_gitignore from './Kentico';
import KiCad_gitignore from './KiCad';
import Kohana_gitignore from './Kohana';
import Kotlin_gitignore from './Kotlin';
import LabVIEW_gitignore from './LabVIEW';
import Laravel_gitignore from './Laravel';
import Lazarus_gitignore from './Lazarus';
import Leiningen_gitignore from './Leiningen';
import LemonStand_gitignore from './LemonStand';
import LibreOffice_gitignore from './LibreOffice';
import Lilypond_gitignore from './Lilypond';
import Linux_gitignore from './Linux';
import Lithium_gitignore from './Lithium';
import Logtalk_gitignore from './Logtalk';
import Lua_gitignore from './Lua';
import LyX_gitignore from './LyX';
import macOS_gitignore from './macOS';
import Magento_gitignore from './Magento';
import Magento1_gitignore from './Magento1';
import Magento2_gitignore from './Magento2';
import MATLAB_gitignore from './MATLAB';
import Maven_gitignore from './Maven';
import Mercurial_gitignore from './Mercurial';
import Mercury_gitignore from './Mercury';
import Metals_gitignore from './Metals';
import MetaProgrammingSystem_gitignore from './MetaProgrammingSystem';
import Meteor_gitignore from './Meteor';
import MicrosoftOffice_gitignore from './MicrosoftOffice';
import ModelSim_gitignore from './ModelSim';
import Momentics_gitignore from './Momentics';
import MonoDevelop_gitignore from './MonoDevelop';
import Nanoc_gitignore from './Nanoc';
import NetBeans_gitignore from './NetBeans';
import Nikola_gitignore from './Nikola';
import Nim_gitignore from './Nim';
import Ninja_gitignore from './Ninja';
import Node_gitignore from './Node';
import NotepadPP_gitignore from './NotepadPP';
import NWjs_gitignore from './NWjs';
import Objective_C_gitignore from './Objective_C';
import OCaml_gitignore from './OCaml';
import Octave_gitignore from './Octave';
import Opa_gitignore from './Opa';
import OpenCart_gitignore from './OpenCart';
import OpenSSL_gitignore from './OpenSSL';
import OracleForms_gitignore from './OracleForms';
import Otto_gitignore from './Otto';
import Packer_gitignore from './Packer';
import Patch_gitignore from './Patch';
import Perl_gitignore from './Perl';
import Phalcon_gitignore from './Phalcon';
import Phoenix_gitignore from './Phoenix';
import Pimcore_gitignore from './Pimcore';
import PlayFramework_gitignore from './PlayFramework';
import Plone_gitignore from './Plone';
import Prestashop_gitignore from './Prestashop';
import Processing_gitignore from './Processing';
import PSoCCreator_gitignore from './PSoCCreator';
import Puppet_gitignore from './Puppet';
import PureScript_gitignore from './PureScript';
import PuTTY_gitignore from './PuTTY';
import Python_gitignore from './Python';
import Qooxdoo_gitignore from './Qooxdoo';
import Qt_gitignore from './Qt';
import R_gitignore from './R';
import Racket_gitignore from './Racket';
import Rails_gitignore from './Rails';
import Raku_gitignore from './Raku';
import Red_gitignore from './Red';
import Redcar_gitignore from './Redcar';
import Redis_gitignore from './Redis';
import RhodesRhomobile_gitignore from './RhodesRhomobile';
import ROS_gitignore from './ROS';
import ROS2_gitignore from './ROS2';
import Ruby_gitignore from './Ruby';
import Rust_gitignore from './Rust';
import SAM_gitignore from './SAM';
import Sass_gitignore from './Sass';
import SBT_gitignore from './SBT';
import Scala_gitignore from './Scala';
import Scheme_gitignore from './Scheme';
import SCons_gitignore from './SCons';
import Scrivener_gitignore from './Scrivener';
import Sdcc_gitignore from './Sdcc';
import SeamGen_gitignore from './SeamGen';
import SketchUp_gitignore from './SketchUp';
import SlickEdit_gitignore from './SlickEdit';
import Smalltalk_gitignore from './Smalltalk';
import Snap_gitignore from './Snap';
import SPFx_gitignore from './SPFx';
import Splunk_gitignore from './Splunk';
import Stata_gitignore from './Stata';
import Stella_gitignore from './Stella';
import Strapi_gitignore from './Strapi';
import SublimeText_gitignore from './SublimeText';
import SugarCRM_gitignore from './SugarCRM';
import SVN_gitignore from './SVN';
import Swift_gitignore from './Swift';
import Symfony_gitignore from './Symfony';
import SymphonyCMS_gitignore from './SymphonyCMS';
import SynopsysVCS_gitignore from './SynopsysVCS';
import Tags_gitignore from './Tags';
import Terraform_gitignore from './Terraform';
import TeX_gitignore from './TeX';
import TextMate_gitignore from './TextMate';
import Textpattern_gitignore from './Textpattern';
import ThinkPHP_gitignore from './ThinkPHP';
import TortoiseGit_gitignore from './TortoiseGit';
import TurboGears2_gitignore from './TurboGears2';
import TwinCAT3_gitignore from './TwinCAT3';
import Typo3_gitignore from './Typo3';
import Umbraco_gitignore from './Umbraco';
import Unity_gitignore from './Unity';
import UnrealEngine_gitignore from './UnrealEngine';
import V_gitignore from './V';
import Vagrant_gitignore from './Vagrant';
import Vim_gitignore from './Vim';
import VirtualEnv_gitignore from './VirtualEnv';
import Virtuoso_gitignore from './Virtuoso';
import VisualStudio_gitignore from './VisualStudio';
import VisualStudioCode_gitignore from './VisualStudioCode';
import Vue_gitignore from './Vue';
import VVVV_gitignore from './VVVV';
import Waf_gitignore from './Waf';
import WebMethods_gitignore from './WebMethods';
import Windows_gitignore from './Windows';
import WordPress_gitignore from './WordPress';
import Xcode_gitignore from './Xcode';
import Xilinx_gitignore from './Xilinx';
import XilinxISE_gitignore from './XilinxISE';
import Xojo_gitignore from './Xojo';
import Yeoman_gitignore from './Yeoman';
import Yii_gitignore from './Yii';
import ZendFramework_gitignore from './ZendFramework';
import Zephir_gitignore from './Zephir';


const pattern = {
    "Actionscript": Actionscript_gitignore,
    "Ada": Ada_gitignore,
    "Agda": Agda_gitignore,
    "AltiumDesigner": AltiumDesigner_gitignore,
    "Android": Android_gitignore,
    "Anjuta": Anjuta_gitignore,
    "Ansible": Ansible_gitignore,
    "AppceleratorTitanium": AppceleratorTitanium_gitignore,
    "AppEngine": AppEngine_gitignore,
    "Archives": Archives_gitignore,
    "ArchLinuxPackages": ArchLinuxPackages_gitignore,
    "AtmelStudio": AtmelStudio_gitignore,
    "Autotools": Autotools_gitignore,
    "Backup": Backup_gitignore,
    "Bazaar": Bazaar_gitignore,
    "Bazel": Bazel_gitignore,
    "Bitrix": Bitrix_gitignore,
    "BricxCC": BricxCC_gitignore,
    "C": C_gitignore,
    "CakePHP": CakePHP_gitignore,
    "Calabash": Calabash_gitignore,
    "CFWheels": CFWheels_gitignore,
    "ChefCookbook": ChefCookbook_gitignore,
    "Clojure": Clojure_gitignore,
    "Cloud9": Cloud9_gitignore,
    "CMake": CMake_gitignore,
    "CodeIgniter": CodeIgniter_gitignore,
    "CodeKit": CodeKit_gitignore,
    "CodeSniffer": CodeSniffer_gitignore,
    "CommonLisp": CommonLisp_gitignore,
    "Composer": Composer_gitignore,
    "Concrete5": Concrete5_gitignore,
    "Coq": Coq_gitignore,
    "Cordova": Cordova_gitignore,
    "Cpp": Cpp_gitignore,
    "CraftCMS": CraftCMS_gitignore,
    "CUDA": CUDA_gitignore,
    "CVS": CVS_gitignore,
    "D": D_gitignore,
    "Dart": Dart_gitignore,
    "DartEditor": DartEditor_gitignore,
    "Delphi": Delphi_gitignore,
    "Diff": Diff_gitignore,
    "DM": DM_gitignore,
    "Dreamweaver": Dreamweaver_gitignore,
    "Dropbox": Dropbox_gitignore,
    "Drupal": Drupal_gitignore,
    "Drupal7": Drupal7_gitignore,
    "Eagle": Eagle_gitignore,
    "Eclipse": Eclipse_gitignore,
    "EiffelStudio": EiffelStudio_gitignore,
    "Elisp": Elisp_gitignore,
    "Elixir": Elixir_gitignore,
    "Elm": Elm_gitignore,
    "Emacs": Emacs_gitignore,
    "Ensime": Ensime_gitignore,
    "EPiServer": EPiServer_gitignore,
    "Erlang": Erlang_gitignore,
    "Espresso": Espresso_gitignore,
    "esp_idf": esp_idf_gitignore,
    "Exercism": Exercism_gitignore,
    "ExpressionEngine": ExpressionEngine_gitignore,
    "ExtJs": ExtJs_gitignore,
    "Fancy": Fancy_gitignore,
    "Finale": Finale_gitignore,
    "FlexBuilder": FlexBuilder_gitignore,
    "ForceDotCom": ForceDotCom_gitignore,
    "Fortran": Fortran_gitignore,
    "FuelPHP": FuelPHP_gitignore,
    "Gcov": Gcov_gitignore,
    "GitBook": GitBook_gitignore,
    "GNOMEShellExtension": GNOMEShellExtension_gitignore,
    "Go": Go_gitignore,
    "Godot": Godot_gitignore,
    "GPG": GPG_gitignore,
    "Gradle": Gradle_gitignore,
    "Grails": Grails_gitignore,
    "Gretl": Gretl_gitignore,
    "GWT": GWT_gitignore,
    "Haskell": Haskell_gitignore,
    "Hugo": Hugo_gitignore,
    "IAR_EWARM": IAR_EWARM_gitignore,
    "Idris": Idris_gitignore,
    "IGORPro": IGORPro_gitignore,
    "Images": Images_gitignore,
    "InforCMS": InforCMS_gitignore,
    "Java": Java_gitignore,
    "JBoss": JBoss_gitignore,
    "JBoss4": JBoss4_gitignore,
    "JBoss6": JBoss6_gitignore,
    "JDeveloper": JDeveloper_gitignore,
    "Jekyll": Jekyll_gitignore,
    "JENKINS_HOME": JENKINS_HOME_gitignore,
    "JEnv": JEnv_gitignore,
    "JetBrains": JetBrains_gitignore,
    "Jigsaw": Jigsaw_gitignore,
    "Joomla": Joomla_gitignore,
    "Julia": Julia_gitignore,
    "JupyterNotebooks": JupyterNotebooks_gitignore,
    "Kate": Kate_gitignore,
    "KDevelop4": KDevelop4_gitignore,
    "Kentico": Kentico_gitignore,
    "KiCad": KiCad_gitignore,
    "Kohana": Kohana_gitignore,
    "Kotlin": Kotlin_gitignore,
    "LabVIEW": LabVIEW_gitignore,
    "Laravel": Laravel_gitignore,
    "Lazarus": Lazarus_gitignore,
    "Leiningen": Leiningen_gitignore,
    "LemonStand": LemonStand_gitignore,
    "LibreOffice": LibreOffice_gitignore,
    "Lilypond": Lilypond_gitignore,
    "Linux": Linux_gitignore,
    "Lithium": Lithium_gitignore,
    "Logtalk": Logtalk_gitignore,
    "Lua": Lua_gitignore,
    "LyX": LyX_gitignore,
    "macOS": macOS_gitignore,
    "Magento": Magento_gitignore,
    "Magento1": Magento1_gitignore,
    "Magento2": Magento2_gitignore,
    "MATLAB": MATLAB_gitignore,
    "Maven": Maven_gitignore,
    "Mercurial": Mercurial_gitignore,
    "Mercury": Mercury_gitignore,
    "Metals": Metals_gitignore,
    "MetaProgrammingSystem": MetaProgrammingSystem_gitignore,
    "Meteor": Meteor_gitignore,
    "MicrosoftOffice": MicrosoftOffice_gitignore,
    "ModelSim": ModelSim_gitignore,
    "Momentics": Momentics_gitignore,
    "MonoDevelop": MonoDevelop_gitignore,
    "Nanoc": Nanoc_gitignore,
    "NetBeans": NetBeans_gitignore,
    "Nikola": Nikola_gitignore,
    "Nim": Nim_gitignore,
    "Ninja": Ninja_gitignore,
    "Node": Node_gitignore,
    "NotepadPP": NotepadPP_gitignore,
    "NWjs": NWjs_gitignore,
    "Objective_C": Objective_C_gitignore,
    "OCaml": OCaml_gitignore,
    "Octave": Octave_gitignore,
    "Opa": Opa_gitignore,
    "OpenCart": OpenCart_gitignore,
    "OpenSSL": OpenSSL_gitignore,
    "OracleForms": OracleForms_gitignore,
    "Otto": Otto_gitignore,
    "Packer": Packer_gitignore,
    "Patch": Patch_gitignore,
    "Perl": Perl_gitignore,
    "Phalcon": Phalcon_gitignore,
    "Phoenix": Phoenix_gitignore,
    "Pimcore": Pimcore_gitignore,
    "PlayFramework": PlayFramework_gitignore,
    "Plone": Plone_gitignore,
    "Prestashop": Prestashop_gitignore,
    "Processing": Processing_gitignore,
    "PSoCCreator": PSoCCreator_gitignore,
    "Puppet": Puppet_gitignore,
    "PureScript": PureScript_gitignore,
    "PuTTY": PuTTY_gitignore,
    "Python": Python_gitignore,
    "Qooxdoo": Qooxdoo_gitignore,
    "Qt": Qt_gitignore,
    "R": R_gitignore,
    "Racket": Racket_gitignore,
    "Rails": Rails_gitignore,
    "Raku": Raku_gitignore,
    "Red": Red_gitignore,
    "Redcar": Redcar_gitignore,
    "Redis": Redis_gitignore,
    "RhodesRhomobile": RhodesRhomobile_gitignore,
    "ROS": ROS_gitignore,
    "ROS2": ROS2_gitignore,
    "Ruby": Ruby_gitignore,
    "Rust": Rust_gitignore,
    "SAM": SAM_gitignore,
    "Sass": Sass_gitignore,
    "SBT": SBT_gitignore,
    "Scala": Scala_gitignore,
    "Scheme": Scheme_gitignore,
    "SCons": SCons_gitignore,
    "Scrivener": Scrivener_gitignore,
    "Sdcc": Sdcc_gitignore,
    "SeamGen": SeamGen_gitignore,
    "SketchUp": SketchUp_gitignore,
    "SlickEdit": SlickEdit_gitignore,
    "Smalltalk": Smalltalk_gitignore,
    "Snap": Snap_gitignore,
    "SPFx": SPFx_gitignore,
    "Splunk": Splunk_gitignore,
    "Stata": Stata_gitignore,
    "Stella": Stella_gitignore,
    "Strapi": Strapi_gitignore,
    "SublimeText": SublimeText_gitignore,
    "SugarCRM": SugarCRM_gitignore,
    "SVN": SVN_gitignore,
    "Swift": Swift_gitignore,
    "Symfony": Symfony_gitignore,
    "SymphonyCMS": SymphonyCMS_gitignore,
    "SynopsysVCS": SynopsysVCS_gitignore,
    "Tags": Tags_gitignore,
    "Terraform": Terraform_gitignore,
    "TeX": TeX_gitignore,
    "TextMate": TextMate_gitignore,
    "Textpattern": Textpattern_gitignore,
    "ThinkPHP": ThinkPHP_gitignore,
    "TortoiseGit": TortoiseGit_gitignore,
    "TurboGears2": TurboGears2_gitignore,
    "TwinCAT3": TwinCAT3_gitignore,
    "Typo3": Typo3_gitignore,
    "Umbraco": Umbraco_gitignore,
    "Unity": Unity_gitignore,
    "UnrealEngine": UnrealEngine_gitignore,
    "V": V_gitignore,
    "Vagrant": Vagrant_gitignore,
    "Vim": Vim_gitignore,
    "VirtualEnv": VirtualEnv_gitignore,
    "Virtuoso": Virtuoso_gitignore,
    "VisualStudio": VisualStudio_gitignore,
    "VisualStudioCode": VisualStudioCode_gitignore,
    "Vue": Vue_gitignore,
    "VVVV": VVVV_gitignore,
    "Waf": Waf_gitignore,
    "WebMethods": WebMethods_gitignore,
    "Windows": Windows_gitignore,
    "WordPress": WordPress_gitignore,
    "Xcode": Xcode_gitignore,
    "Xilinx": Xilinx_gitignore,
    "XilinxISE": XilinxISE_gitignore,
    "Xojo": Xojo_gitignore,
    "Yeoman": Yeoman_gitignore,
    "Yii": Yii_gitignore,
    "ZendFramework": ZendFramework_gitignore,
    "Zephir": Zephir_gitignore,
};
export default pattern;
