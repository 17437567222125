const pattern = `
!Build/
.last_cover_stats
/META.yml
/META.json
/MYMETA.*
*.o
*.pm.tdy
*.bs

# Devel::Cover
cover_db/

# Devel::NYTProf
nytprof.out

# Dizt::Zilla
/.build/

# Module::Build
_build/
Build
Build.bat

# Module::Install
inc/

# ExtUtils::MakeMaker
/blib/
/_eumm/
/*.gz
/Makefile
/Makefile.old
/MANIFEST.bak
/pm_to_blib
/*.zip
`
export default pattern;
