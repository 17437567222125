const pattern = `
*.tar
*.tar.*
*.jar
*.exe
*.msi
*.zip
*.tgz
*.log
*.log.*
*.sig

pkg/
src/
`
export default pattern;
