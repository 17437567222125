const pattern = `
/.htaccess
/administrator/cache/*
/administrator/components/com_actionlogs/*
/administrator/components/com_admin/*
/administrator/components/com_ajax/*
/administrator/components/com_associations/*
/administrator/components/com_banners/*
/administrator/components/com_cache/*
/administrator/components/com_categories/*
/administrator/components/com_checkin/*
/administrator/components/com_config/*
/administrator/components/com_contact/*
/administrator/components/com_content/*
/administrator/components/com_contenthistory/*
/administrator/components/com_cpanel/*
/administrator/components/com_fields/*
/administrator/components/com_finder/*
/administrator/components/com_installer/*
/administrator/components/com_joomlaupdate/*
/administrator/components/com_languages/*
/administrator/components/com_login/*
/administrator/components/com_media/*
/administrator/components/com_menus/*
/administrator/components/com_messages/*
/administrator/components/com_modules/*
/administrator/components/com_newsfeeds/*
/administrator/components/com_plugins/*
/administrator/components/com_postinstall/*
/administrator/components/com_privacy/*
/administrator/components/com_redirect/*
/administrator/components/com_search/*
/administrator/components/com_tags/*
/administrator/components/com_templates/*
/administrator/components/com_users/*
/administrator/help/*
/administrator/includes/*
/administrator/index.php
/administrator/language/en-GB/en-GB.com_actionlogs.ini
/administrator/language/en-GB/en-GB.com_actionlogs.sys.ini
/administrator/language/en-GB/en-GB.com_admin.ini
/administrator/language/en-GB/en-GB.com_admin.sys.ini
/administrator/language/en-GB/en-GB.com_ajax.ini
/administrator/language/en-GB/en-GB.com_ajax.sys.ini
/administrator/language/en-GB/en-GB.com_associations.ini
/administrator/language/en-GB/en-GB.com_associations.sys.ini
/administrator/language/en-GB/en-GB.com_banners.ini
/administrator/language/en-GB/en-GB.com_banners.sys.ini
/administrator/language/en-GB/en-GB.com_cache.ini
/administrator/language/en-GB/en-GB.com_cache.sys.ini
/administrator/language/en-GB/en-GB.com_categories.ini
/administrator/language/en-GB/en-GB.com_categories.sys.ini
/administrator/language/en-GB/en-GB.com_checkin.ini
/administrator/language/en-GB/en-GB.com_checkin.sys.ini
/administrator/language/en-GB/en-GB.com_config.ini
/administrator/language/en-GB/en-GB.com_config.sys.ini
/administrator/language/en-GB/en-GB.com_contact.ini
/administrator/language/en-GB/en-GB.com_contact.sys.ini
/administrator/language/en-GB/en-GB.com_content.ini
/administrator/language/en-GB/en-GB.com_content.sys.ini
/administrator/language/en-GB/en-GB.com_contenthistory.ini
/administrator/language/en-GB/en-GB.com_contenthistory.sys.ini
/administrator/language/en-GB/en-GB.com_cpanel.ini
/administrator/language/en-GB/en-GB.com_cpanel.sys.ini
/administrator/language/en-GB/en-GB.com_fields.ini
/administrator/language/en-GB/en-GB.com_fields.sys.ini
/administrator/language/en-GB/en-GB.com_finder.ini
/administrator/language/en-GB/en-GB.com_finder.sys.ini
/administrator/language/en-GB/en-GB.com_installer.ini
/administrator/language/en-GB/en-GB.com_installer.sys.ini
/administrator/language/en-GB/en-GB.com_joomlaupdate.ini
/administrator/language/en-GB/en-GB.com_joomlaupdate.sys.ini
/administrator/language/en-GB/en-GB.com_languages.ini
/administrator/language/en-GB/en-GB.com_languages.sys.ini
/administrator/language/en-GB/en-GB.com_login.ini
/administrator/language/en-GB/en-GB.com_login.sys.ini
/administrator/language/en-GB/en-GB.com_mailto.sys.ini
/administrator/language/en-GB/en-GB.com_media.ini
/administrator/language/en-GB/en-GB.com_media.sys.ini
/administrator/language/en-GB/en-GB.com_menus.ini
/administrator/language/en-GB/en-GB.com_menus.sys.ini
/administrator/language/en-GB/en-GB.com_messages.ini
/administrator/language/en-GB/en-GB.com_messages.sys.ini
/administrator/language/en-GB/en-GB.com_modules.ini
/administrator/language/en-GB/en-GB.com_modules.sys.ini
/administrator/language/en-GB/en-GB.com_newsfeeds.ini
/administrator/language/en-GB/en-GB.com_newsfeeds.sys.ini
/administrator/language/en-GB/en-GB.com_plugins.ini
/administrator/language/en-GB/en-GB.com_plugins.sys.ini
/administrator/language/en-GB/en-GB.com_postinstall.ini
/administrator/language/en-GB/en-GB.com_postinstall.sys.ini
/administrator/language/en-GB/en-GB.com_privacy.ini
/administrator/language/en-GB/en-GB.com_privacy.sys.ini
/administrator/language/en-GB/en-GB.com_redirect.ini
/administrator/language/en-GB/en-GB.com_redirect.sys.ini
/administrator/language/en-GB/en-GB.com_search.ini
/administrator/language/en-GB/en-GB.com_search.sys.ini
/administrator/language/en-GB/en-GB.com_tags.ini
/administrator/language/en-GB/en-GB.com_tags.sys.ini
/administrator/language/en-GB/en-GB.com_templates.ini
/administrator/language/en-GB/en-GB.com_templates.sys.ini
/administrator/language/en-GB/en-GB.com_users.ini
/administrator/language/en-GB/en-GB.com_users.sys.ini
/administrator/language/en-GB/en-GB.com_weblinks.ini
/administrator/language/en-GB/en-GB.com_weblinks.sys.ini
/administrator/language/en-GB/en-GB.com_wrapper.ini
/administrator/language/en-GB/en-GB.com_wrapper.sys.ini
/administrator/language/en-GB/en-GB.ini
/administrator/language/en-GB/en-GB.lib_joomla.ini
/administrator/language/en-GB/en-GB.localise.php
/administrator/language/en-GB/en-GB.mod_custom.ini
/administrator/language/en-GB/en-GB.mod_custom.sys.ini
/administrator/language/en-GB/en-GB.mod_feed.ini
/administrator/language/en-GB/en-GB.mod_feed.sys.ini
/administrator/language/en-GB/en-GB.mod_latest.ini
/administrator/language/en-GB/en-GB.mod_latest.sys.ini
/administrator/language/en-GB/en-GB.mod_latestactions.ini
/administrator/language/en-GB/en-GB.mod_latestactions.sys.ini
/administrator/language/en-GB/en-GB.mod_logged.ini
/administrator/language/en-GB/en-GB.mod_logged.sys.ini
/administrator/language/en-GB/en-GB.mod_login.ini
/administrator/language/en-GB/en-GB.mod_login.sys.ini
/administrator/language/en-GB/en-GB.mod_menu.ini
/administrator/language/en-GB/en-GB.mod_menu.sys.ini
/administrator/language/en-GB/en-GB.mod_multilangstatus.ini
/administrator/language/en-GB/en-GB.mod_multilangstatus.sys.ini
/administrator/language/en-GB/en-GB.mod_online.ini
/administrator/language/en-GB/en-GB.mod_online.sys.ini
/administrator/language/en-GB/en-GB.mod_popular.ini
/administrator/language/en-GB/en-GB.mod_popular.sys.ini
/administrator/language/en-GB/en-GB.mod_privacy_dashboard.ini
/administrator/language/en-GB/en-GB.mod_privacy_dashboard.sys.ini
/administrator/language/en-GB/en-GB.mod_quickicon.ini
/administrator/language/en-GB/en-GB.mod_quickicon.sys.ini
/administrator/language/en-GB/en-GB.mod_sampledata.ini
/administrator/language/en-GB/en-GB.mod_sampledata.sys.ini
/administrator/language/en-GB/en-GB.mod_stats_admin.ini
/administrator/language/en-GB/en-GB.mod_stats_admin.sys.ini
/administrator/language/en-GB/en-GB.mod_status.ini
/administrator/language/en-GB/en-GB.mod_status.sys.ini
/administrator/language/en-GB/en-GB.mod_submenu.ini
/administrator/language/en-GB/en-GB.mod_submenu.sys.ini
/administrator/language/en-GB/en-GB.mod_title.ini
/administrator/language/en-GB/en-GB.mod_title.sys.ini
/administrator/language/en-GB/en-GB.mod_toolbar.ini
/administrator/language/en-GB/en-GB.mod_toolbar.sys.ini
/administrator/language/en-GB/en-GB.mod_unread.ini
/administrator/language/en-GB/en-GB.mod_unread.sys.ini
/administrator/language/en-GB/en-GB.mod_version.ini
/administrator/language/en-GB/en-GB.mod_version.sys.ini
/administrator/language/en-GB/en-GB.plg_actionlog_joomla.ini
/administrator/language/en-GB/en-GB.plg_actionlog_joomla.sys.ini
/administrator/language/en-GB/en-GB.plg_authentication_cookie.ini
/administrator/language/en-GB/en-GB.plg_authentication_cookie.sys.ini
/administrator/language/en-GB/en-GB.plg_authentication_example.ini
/administrator/language/en-GB/en-GB.plg_authentication_example.sys.ini
/administrator/language/en-GB/en-GB.plg_authentication_gmail.ini
/administrator/language/en-GB/en-GB.plg_authentication_gmail.sys.ini
/administrator/language/en-GB/en-GB.plg_authentication_joomla.ini
/administrator/language/en-GB/en-GB.plg_authentication_joomla.sys.ini
/administrator/language/en-GB/en-GB.plg_authentication_ldap.ini
/administrator/language/en-GB/en-GB.plg_authentication_ldap.sys.ini
/administrator/language/en-GB/en-GB.plg_captcha_recaptcha.ini
/administrator/language/en-GB/en-GB.plg_captcha_recaptcha.sys.ini
/administrator/language/en-GB/en-GB.plg_captcha_recaptcha_invisible.ini
/administrator/language/en-GB/en-GB.plg_captcha_recaptcha_invisible.sys.ini
/administrator/language/en-GB/en-GB.plg_content_confirmconsent.ini
/administrator/language/en-GB/en-GB.plg_content_confirmconsent.sys.ini
/administrator/language/en-GB/en-GB.plg_content_contact.ini
/administrator/language/en-GB/en-GB.plg_content_contact.sys.ini
/administrator/language/en-GB/en-GB.plg_content_emailcloak.ini
/administrator/language/en-GB/en-GB.plg_content_emailcloak.sys.ini
/administrator/language/en-GB/en-GB.plg_content_fields.ini
/administrator/language/en-GB/en-GB.plg_content_fields.sys.ini
/administrator/language/en-GB/en-GB.plg_content_finder.ini
/administrator/language/en-GB/en-GB.plg_content_finder.sys.ini
/administrator/language/en-GB/en-GB.plg_content_geshi.ini
/administrator/language/en-GB/en-GB.plg_content_geshi.sys.ini
/administrator/language/en-GB/en-GB.plg_content_joomla.ini
/administrator/language/en-GB/en-GB.plg_content_joomla.sys.ini
/administrator/language/en-GB/en-GB.plg_content_loadmodule.ini
/administrator/language/en-GB/en-GB.plg_content_loadmodule.sys.ini
/administrator/language/en-GB/en-GB.plg_content_pagebreak.ini
/administrator/language/en-GB/en-GB.plg_content_pagebreak.sys.ini
/administrator/language/en-GB/en-GB.plg_content_pagenavigation.ini
/administrator/language/en-GB/en-GB.plg_content_pagenavigation.sys.ini
/administrator/language/en-GB/en-GB.plg_content_vote.ini
/administrator/language/en-GB/en-GB.plg_content_vote.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_article.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_article.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_contact.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_contact.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_fields.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_fields.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_image.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_image.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_menu.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_menu.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_module.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_module.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_pagebreak.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_pagebreak.sys.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_readmore.ini
/administrator/language/en-GB/en-GB.plg_editors-xtd_readmore.sys.ini
/administrator/language/en-GB/en-GB.plg_editors_codemirror.ini
/administrator/language/en-GB/en-GB.plg_editors_codemirror.sys.ini
/administrator/language/en-GB/en-GB.plg_editors_none.ini
/administrator/language/en-GB/en-GB.plg_editors_none.sys.ini
/administrator/language/en-GB/en-GB.plg_editors_tinymce.ini
/administrator/language/en-GB/en-GB.plg_editors_tinymce.sys.ini
/administrator/language/en-GB/en-GB.plg_extension_joomla.ini
/administrator/language/en-GB/en-GB.plg_extension_joomla.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_calendar.ini
/administrator/language/en-GB/en-GB.plg_fields_calendar.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_checkboxes.ini
/administrator/language/en-GB/en-GB.plg_fields_checkboxes.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_color.ini
/administrator/language/en-GB/en-GB.plg_fields_color.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_editor.ini
/administrator/language/en-GB/en-GB.plg_fields_editor.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_image.ini
/administrator/language/en-GB/en-GB.plg_fields_image.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_imagelist.ini
/administrator/language/en-GB/en-GB.plg_fields_imagelist.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_integer.ini
/administrator/language/en-GB/en-GB.plg_fields_integer.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_list.ini
/administrator/language/en-GB/en-GB.plg_fields_list.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_media.ini
/administrator/language/en-GB/en-GB.plg_fields_media.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_radio.ini
/administrator/language/en-GB/en-GB.plg_fields_radio.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_repeatable.ini
/administrator/language/en-GB/en-GB.plg_fields_repeatable.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_sql.ini
/administrator/language/en-GB/en-GB.plg_fields_sql.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_text.ini
/administrator/language/en-GB/en-GB.plg_fields_text.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_textarea.ini
/administrator/language/en-GB/en-GB.plg_fields_textarea.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_url.ini
/administrator/language/en-GB/en-GB.plg_fields_url.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_user.ini
/administrator/language/en-GB/en-GB.plg_fields_user.sys.ini
/administrator/language/en-GB/en-GB.plg_fields_usergrouplist.ini
/administrator/language/en-GB/en-GB.plg_fields_usergrouplist.sys.ini
/administrator/language/en-GB/en-GB.plg_finder_categories.ini
/administrator/language/en-GB/en-GB.plg_finder_categories.sys.ini
/administrator/language/en-GB/en-GB.plg_finder_contacts.ini
/administrator/language/en-GB/en-GB.plg_finder_contacts.sys.ini
/administrator/language/en-GB/en-GB.plg_finder_content.ini
/administrator/language/en-GB/en-GB.plg_finder_content.sys.ini
/administrator/language/en-GB/en-GB.plg_finder_newsfeeds.ini
/administrator/language/en-GB/en-GB.plg_finder_newsfeeds.sys.ini
/administrator/language/en-GB/en-GB.plg_finder_tags.ini
/administrator/language/en-GB/en-GB.plg_finder_tags.sys.ini
/administrator/language/en-GB/en-GB.plg_finder_weblinks.ini
/administrator/language/en-GB/en-GB.plg_finder_weblinks.sys.ini
/administrator/language/en-GB/en-GB.plg_installer_folderinstaller.ini
/administrator/language/en-GB/en-GB.plg_installer_folderinstaller.sys.ini
/administrator/language/en-GB/en-GB.plg_installer_packageinstaller.ini
/administrator/language/en-GB/en-GB.plg_installer_packageinstaller.sys.ini
/administrator/language/en-GB/en-GB.plg_installer_urlinstaller.ini
/administrator/language/en-GB/en-GB.plg_installer_urlinstaller.sys.ini
/administrator/language/en-GB/en-GB.plg_installer_webinstaller.ini
/administrator/language/en-GB/en-GB.plg_installer_webinstaller.sys.ini
/administrator/language/en-GB/en-GB.plg_privacy_actionlogs.ini
/administrator/language/en-GB/en-GB.plg_privacy_actionlogs.sys.ini
/administrator/language/en-GB/en-GB.plg_privacy_consents.ini
/administrator/language/en-GB/en-GB.plg_privacy_consents.sys.ini
/administrator/language/en-GB/en-GB.plg_privacy_contact.ini
/administrator/language/en-GB/en-GB.plg_privacy_contact.sys.ini
/administrator/language/en-GB/en-GB.plg_privacy_content.ini
/administrator/language/en-GB/en-GB.plg_privacy_content.sys.ini
/administrator/language/en-GB/en-GB.plg_privacy_message.ini
/administrator/language/en-GB/en-GB.plg_privacy_message.sys.ini
/administrator/language/en-GB/en-GB.plg_privacy_user.ini
/administrator/language/en-GB/en-GB.plg_privacy_user.sys.ini
/administrator/language/en-GB/en-GB.plg_quickicon_extensionupdate.ini
/administrator/language/en-GB/en-GB.plg_quickicon_extensionupdate.sys.ini
/administrator/language/en-GB/en-GB.plg_quickicon_joomlaupdate.ini
/administrator/language/en-GB/en-GB.plg_quickicon_joomlaupdate.sys.ini
/administrator/language/en-GB/en-GB.plg_quickicon_phpversioncheck.ini
/administrator/language/en-GB/en-GB.plg_quickicon_phpversioncheck.sys.ini
/administrator/language/en-GB/en-GB.plg_quickicon_privacycheck.ini
/administrator/language/en-GB/en-GB.plg_quickicon_privacycheck.sys.ini
/administrator/language/en-GB/en-GB.plg_sampledata_blog.ini
/administrator/language/en-GB/en-GB.plg_sampledata_blog.sys.ini
/administrator/language/en-GB/en-GB.plg_search_categories.ini
/administrator/language/en-GB/en-GB.plg_search_categories.sys.ini
/administrator/language/en-GB/en-GB.plg_search_contacts.ini
/administrator/language/en-GB/en-GB.plg_search_contacts.sys.ini
/administrator/language/en-GB/en-GB.plg_search_content.ini
/administrator/language/en-GB/en-GB.plg_search_content.sys.ini
/administrator/language/en-GB/en-GB.plg_search_newsfeeds.ini
/administrator/language/en-GB/en-GB.plg_search_newsfeeds.sys.ini
/administrator/language/en-GB/en-GB.plg_search_tags.ini
/administrator/language/en-GB/en-GB.plg_search_tags.sys.ini
/administrator/language/en-GB/en-GB.plg_search_weblinks.ini
/administrator/language/en-GB/en-GB.plg_search_weblinks.sys.ini
/administrator/language/en-GB/en-GB.plg_system_actionlogs.ini
/administrator/language/en-GB/en-GB.plg_system_actionlogs.sys.ini
/administrator/language/en-GB/en-GB.plg_system_cache.ini
/administrator/language/en-GB/en-GB.plg_system_cache.sys.ini
/administrator/language/en-GB/en-GB.plg_system_debug.ini
/administrator/language/en-GB/en-GB.plg_system_debug.sys.ini
/administrator/language/en-GB/en-GB.plg_system_fields.ini
/administrator/language/en-GB/en-GB.plg_system_fields.sys.ini
/administrator/language/en-GB/en-GB.plg_system_highlight.ini
/administrator/language/en-GB/en-GB.plg_system_highlight.sys.ini
/administrator/language/en-GB/en-GB.plg_system_languagecode.ini
/administrator/language/en-GB/en-GB.plg_system_languagecode.sys.ini
/administrator/language/en-GB/en-GB.plg_system_languagefilter.ini
/administrator/language/en-GB/en-GB.plg_system_languagefilter.sys.ini
/administrator/language/en-GB/en-GB.plg_system_log.ini
/administrator/language/en-GB/en-GB.plg_system_log.sys.ini
/administrator/language/en-GB/en-GB.plg_system_logout.ini
/administrator/language/en-GB/en-GB.plg_system_logout.sys.ini
/administrator/language/en-GB/en-GB.plg_system_logrotation.ini
/administrator/language/en-GB/en-GB.plg_system_logrotation.sys.ini
/administrator/language/en-GB/en-GB.plg_system_p3p.ini
/administrator/language/en-GB/en-GB.plg_system_p3p.sys.ini
/administrator/language/en-GB/en-GB.plg_system_privacyconsent.ini
/administrator/language/en-GB/en-GB.plg_system_privacyconsent.sys.ini
/administrator/language/en-GB/en-GB.plg_system_redirect.ini
/administrator/language/en-GB/en-GB.plg_system_redirect.sys.ini
/administrator/language/en-GB/en-GB.plg_system_remember.ini
/administrator/language/en-GB/en-GB.plg_system_remember.sys.ini
/administrator/language/en-GB/en-GB.plg_system_sef.ini
/administrator/language/en-GB/en-GB.plg_system_sef.sys.ini
/administrator/language/en-GB/en-GB.plg_system_sessiongc.ini
/administrator/language/en-GB/en-GB.plg_system_sessiongc.sys.ini
/administrator/language/en-GB/en-GB.plg_system_stats.ini
/administrator/language/en-GB/en-GB.plg_system_stats.sys.ini
/administrator/language/en-GB/en-GB.plg_system_updatenotification.ini
/administrator/language/en-GB/en-GB.plg_system_updatenotification.sys.ini
/administrator/language/en-GB/en-GB.plg_twofactorauth_totp.ini
/administrator/language/en-GB/en-GB.plg_twofactorauth_totp.sys.ini
/administrator/language/en-GB/en-GB.plg_twofactorauth_yubikey.ini
/administrator/language/en-GB/en-GB.plg_twofactorauth_yubikey.sys.ini
/administrator/language/en-GB/en-GB.plg_user_contactcreator.ini
/administrator/language/en-GB/en-GB.plg_user_contactcreator.sys.ini
/administrator/language/en-GB/en-GB.plg_user_joomla.ini
/administrator/language/en-GB/en-GB.plg_user_joomla.sys.ini
/administrator/language/en-GB/en-GB.plg_user_profile.ini
/administrator/language/en-GB/en-GB.plg_user_profile.sys.ini
/administrator/language/en-GB/en-GB.plg_user_terms.ini
/administrator/language/en-GB/en-GB.plg_user_terms.sys.ini
/administrator/language/en-GB/en-GB.tpl_hathor.ini
/administrator/language/en-GB/en-GB.tpl_hathor.sys.ini
/administrator/language/en-GB/en-GB.tpl_isis.ini
/administrator/language/en-GB/en-GB.tpl_isis.sys.ini
/administrator/language/en-GB/en-GB.xml
/administrator/language/en-GB/install.xml
/administrator/language/overrides/*
/administrator/language/index.html
/administrator/logs/*
/administrator/manifests/files/joomla.xml
/administrator/manifests/libraries/fof.xml
/administrator/manifests/libraries/idna_convert.xml
/administrator/manifests/libraries/joomla.xml
/administrator/manifests/libraries/phpass.xml
/administrator/manifests/libraries/phputf8.xml
/administrator/manifests/packages/pkg_en-GB.xml
/administrator/manifests/packages/index.html
/administrator/modules/mod_custom/*
/administrator/modules/mod_feed/*
/administrator/modules/mod_latest/*
/administrator/modules/mod_latestactions/*
/administrator/modules/mod_logged/*
/administrator/modules/mod_login/*
/administrator/modules/mod_menu/*
/administrator/modules/mod_multilangstatus/*
/administrator/modules/mod_online/*
/administrator/modules/mod_popular/*
/administrator/modules/mod_privacy_dashboard/*
/administrator/modules/mod_quickicon/*
/administrator/modules/mod_sampledata/*
/administrator/modules/mod_stats_admin/*
/administrator/modules/mod_status/*
/administrator/modules/mod_submenu/*
/administrator/modules/mod_title/*
/administrator/modules/mod_toolbar/*
/administrator/modules/mod_unread/*
/administrator/modules/mod_version/*
/administrator/templates/hathor/*
/administrator/templates/isis/*
/administrator/templates/system/*
/bin/*
/cache/*
/cli/*
/components/com_ajax/*
/components/com_banners/*
/components/com_config/*
/components/com_contact/*
/components/com_content/*
/components/com_contenthistory/*
/components/com_fields/*
/components/com_finder/*
/components/com_mailto/*
/components/com_media/*
/components/com_menus/*
/components/com_modules/*
/components/com_newsfeeds/*
/components/com_privacy/*
/components/com_search/*
/components/com_tags/*
/components/com_users/*
/components/com_wrapper/*
/components/index.html
/images/banners/*
/images/headers/*
/images/sampledata/*
/images/index.html
/images/joomla*
/images/powered_by.png
/includes/*
/installation/*
/language/en-GB/en-GB.com_ajax.ini
/language/en-GB/en-GB.com_config.ini
/language/en-GB/en-GB.com_contact.ini
/language/en-GB/en-GB.com_content.ini
/language/en-GB/en-GB.com_finder.ini
/language/en-GB/en-GB.com_mailto.ini
/language/en-GB/en-GB.com_media.ini
/language/en-GB/en-GB.com_messages.ini
/language/en-GB/en-GB.com_newsfeeds.ini
/language/en-GB/en-GB.com_privacy.ini
/language/en-GB/en-GB.com_search.ini
/language/en-GB/en-GB.com_tags.ini
/language/en-GB/en-GB.com_users.ini
/language/en-GB/en-GB.com_weblinks.ini
/language/en-GB/en-GB.com_wrapper.ini
/language/en-GB/en-GB.files_joomla.sys.ini
/language/en-GB/en-GB.finder_cli.ini
/language/en-GB/en-GB.ini
/language/en-GB/en-GB.lib_fof.ini
/language/en-GB/en-GB.lib_fof.sys.ini
/language/en-GB/en-GB.lib_idna_convert.sys.ini
/language/en-GB/en-GB.lib_joomla.ini
/language/en-GB/en-GB.lib_joomla.sys.ini
/language/en-GB/en-GB.lib_phpass.sys.ini
/language/en-GB/en-GB.lib_phpmailer.sys.ini
/language/en-GB/en-GB.lib_phputf8.sys.ini
/language/en-GB/en-GB.lib_simplepie.sys.ini
/language/en-GB/en-GB.localise.php
/language/en-GB/en-GB.mod_articles_archive.ini
/language/en-GB/en-GB.mod_articles_archive.sys.ini
/language/en-GB/en-GB.mod_articles_categories.ini
/language/en-GB/en-GB.mod_articles_categories.sys.ini
/language/en-GB/en-GB.mod_articles_category.ini
/language/en-GB/en-GB.mod_articles_category.sys.ini
/language/en-GB/en-GB.mod_articles_latest.ini
/language/en-GB/en-GB.mod_articles_latest.sys.ini
/language/en-GB/en-GB.mod_articles_news.ini
/language/en-GB/en-GB.mod_articles_news.sys.ini
/language/en-GB/en-GB.mod_articles_popular.ini
/language/en-GB/en-GB.mod_articles_popular.sys.ini
/language/en-GB/en-GB.mod_banners.ini
/language/en-GB/en-GB.mod_banners.sys.ini
/language/en-GB/en-GB.mod_breadcrumbs.ini
/language/en-GB/en-GB.mod_breadcrumbs.sys.ini
/language/en-GB/en-GB.mod_custom.ini
/language/en-GB/en-GB.mod_custom.sys.ini
/language/en-GB/en-GB.mod_feed.ini
/language/en-GB/en-GB.mod_feed.sys.ini
/language/en-GB/en-GB.mod_finder.ini
/language/en-GB/en-GB.mod_finder.sys.ini
/language/en-GB/en-GB.mod_footer.ini
/language/en-GB/en-GB.mod_footer.sys.ini
/language/en-GB/en-GB.mod_languages.ini
/language/en-GB/en-GB.mod_languages.sys.ini
/language/en-GB/en-GB.mod_login.ini
/language/en-GB/en-GB.mod_login.sys.ini
/language/en-GB/en-GB.mod_menu.ini
/language/en-GB/en-GB.mod_menu.sys.ini
/language/en-GB/en-GB.mod_random_image.ini
/language/en-GB/en-GB.mod_random_image.sys.ini
/language/en-GB/en-GB.mod_related_items.ini
/language/en-GB/en-GB.mod_related_items.sys.ini
/language/en-GB/en-GB.mod_search.ini
/language/en-GB/en-GB.mod_search.sys.ini
/language/en-GB/en-GB.mod_stats.ini
/language/en-GB/en-GB.mod_stats.sys.ini
/language/en-GB/en-GB.mod_syndicate.ini
/language/en-GB/en-GB.mod_syndicate.sys.ini
/language/en-GB/en-GB.mod_tags_popular.ini
/language/en-GB/en-GB.mod_tags_popular.sys.ini
/language/en-GB/en-GB.mod_tags_similar.ini
/language/en-GB/en-GB.mod_tags_similar.sys.ini
/language/en-GB/en-GB.mod_users_latest.ini
/language/en-GB/en-GB.mod_users_latest.sys.ini
/language/en-GB/en-GB.mod_weblinks.ini
/language/en-GB/en-GB.mod_weblinks.sys.ini
/language/en-GB/en-GB.mod_whosonline.ini
/language/en-GB/en-GB.mod_whosonline.sys.ini
/language/en-GB/en-GB.mod_wrapper.ini
/language/en-GB/en-GB.mod_wrapper.sys.ini
/language/en-GB/en-GB.tpl_atomic.ini
/language/en-GB/en-GB.tpl_atomic.sys.ini
/language/en-GB/en-GB.tpl_beez3.ini
/language/en-GB/en-GB.tpl_beez3.sys.ini
/language/en-GB/en-GB.tpl_beez5.ini
/language/en-GB/en-GB.tpl_beez5.sys.ini
/language/en-GB/en-GB.tpl_beez_20.ini
/language/en-GB/en-GB.tpl_beez_20.sys.ini
/language/en-GB/en-GB.tpl_protostar.ini
/language/en-GB/en-GB.tpl_protostar.sys.ini
/language/en-GB/en-GB.xml
/language/en-GB/install.xml
/language/overrides/*
/language/index.html
/layouts/joomla/*
/layouts/libraries/*
/layouts/plugins/*
/layouts/index.html
/libraries/cms/*
/libraries/fof/*
/libraries/idna_convert/*
/libraries/joomla/*
/libraries/legacy/*
/libraries/php-encryption/*
/libraries/phpass/*
/libraries/phpmailer/*
/libraries/phputf8/*
/libraries/simplepie/*
/libraries/src/*
/libraries/vendor/*
/libraries/classmap.php
/libraries/cms.php
/libraries/import.legacy.php
/libraries/import.php
/libraries/index.html
/libraries/loader.php
/media/cms/*
/media/com_associations/*
/media/com_contact/*
/media/com_content/*
/media/com_contenthistory/*
/media/com_fields/*
/media/com_finder/*
/media/com_joomlaupdate/*
/media/com_menus/*
/media/com_modules/*
/media/com_wrapper/*
/media/contacts/*
/media/editors/*
/media/jui/*
/media/mailto/*
/media/media/*
/media/mod_languages/*
/media/mod_sampledata/*
/media/overrider/*
/media/plg_captcha_recaptcha/*
/media/plg_captcha_recaptcha_invisible/*
/media/plg_quickicon_extensionupdate/*
/media/plg_quickicon_joomlaupdate/*
/media/plg_quickicon_privacycheck/*
/media/plg_system_highlight/*
/media/plg_system_stats/*
/media/plg_twofactorauth_totp/*
/media/system/*
/media/index.html
/modules/mod_articles_archive/*
/modules/mod_articles_categories/*
/modules/mod_articles_category/*
/modules/mod_articles_latest/*
/modules/mod_articles_news/*
/modules/mod_articles_popular/*
/modules/mod_banners/*
/modules/mod_breadcrumbs/*
/modules/mod_custom/*
/modules/mod_feed/*
/modules/mod_finder/*
/modules/mod_footer/*
/modules/mod_languages/*
/modules/mod_login/*
/modules/mod_menu/*
/modules/mod_random_image/*
/modules/mod_related_items/*
/modules/mod_search/*
/modules/mod_stats/*
/modules/mod_syndicate/*
/modules/mod_tags_popular/*
/modules/mod_tags_similar/*
/modules/mod_users_latest/*
/modules/mod_whosonline/*
/modules/mod_wrapper/*
/modules/index.html
/plugins/actionlog/joomla/*
/plugins/authentication/cookie/*
/plugins/authentication/example/*
/plugins/authentication/gmail/*
/plugins/authentication/joomla/*
/plugins/authentication/ldap/*
/plugins/captcha/recaptcha/*
/plugins/captcha/recaptcha_invisible/*
/plugins/content/confirmconsent/*
/plugins/content/contact/*
/plugins/content/emailcloak/*
/plugins/content/example/*
/plugins/content/fields/*
/plugins/content/finder/*
/plugins/content/geshi/*
/plugins/content/joomla/*
/plugins/content/loadmodule/*
/plugins/content/pagebreak/*
/plugins/content/pagenavigation/*
/plugins/content/vote/*
/plugins/editors/codemirror/*
/plugins/editors/none/*
/plugins/editors/tinymce/*
/plugins/editors-xtd/article/*
/plugins/editors-xtd/contact/*
/plugins/editors-xtd/fields/*
/plugins/editors-xtd/image/*
/plugins/editors-xtd/menu/*
/plugins/editors-xtd/module/*
/plugins/editors-xtd/pagebreak/*
/plugins/editors-xtd/readmore/*
/plugins/extension/example/*
/plugins/extension/joomla/*
/plugins/fields/calendar/*
/plugins/fields/checkboxes/*
/plugins/fields/color/*
/plugins/fields/editor/*
/plugins/fields/imagelist/*
/plugins/fields/integer/*
/plugins/fields/list/*
/plugins/fields/media/*
/plugins/fields/radio/*
/plugins/fields/repeatable/*
/plugins/fields/sql/*
/plugins/fields/text/*
/plugins/fields/textarea/*
/plugins/fields/url/*
/plugins/fields/user/*
/plugins/fields/usergrouplist/*
/plugins/finder/categories/*
/plugins/finder/contacts/*
/plugins/finder/content/*
/plugins/finder/newsfeeds/*
/plugins/finder/tags/*
/plugins/installer/folderinstaller/*
/plugins/installer/packageinstaller/*
/plugins/installer/urlinstaller/*
/plugins/privacy/actionlogs/*
/plugins/privacy/consents/*
/plugins/privacy/contact/*
/plugins/privacy/content/*
/plugins/privacy/message/*
/plugins/privacy/user/*
/plugins/quickicon/extensionupdate/*
/plugins/quickicon/joomlaupdate/*
/plugins/quickicon/phpversioncheck/*
/plugins/quickicon/privacycheck/*
/plugins/quickicon/index.html
/plugins/sampledata/blog/*
/plugins/search/categories/*
/plugins/search/contacts/*
/plugins/search/content/*
/plugins/search/newsfeeds/*
/plugins/search/tags/*
/plugins/search/weblinks/*
/plugins/search/index.html
/plugins/system/actionlogs/*
/plugins/system/cache/*
/plugins/system/debug/*
/plugins/system/fields/*
/plugins/system/highlight/*
/plugins/system/languagecode/*
/plugins/system/languagefilter/*
/plugins/system/log/*
/plugins/system/logout/*
/plugins/system/logrotation/*
/plugins/system/p3p/*
/plugins/system/privacyconsent/*
/plugins/system/redirect/*
/plugins/system/remember/*
/plugins/system/sef/*
/plugins/system/sessiongc/*
/plugins/system/stats/*
/plugins/system/updatenotification/*
/plugins/system/index.html
/plugins/twofactorauth/totp/*
/plugins/twofactorauth/yubikey/*
/plugins/user/contactcreator/*
/plugins/user/example/*
/plugins/user/joomla/*
/plugins/user/profile/*
/plugins/user/terms/*
/plugins/user/index.html
/plugins/index.html
/templates/beez3/*
/templates/protostar/*
/templates/system/*
/templates/index.html
/tmp/*
/configuration.php
/htaccess.txt
/index.php
/joomla.xml
/LICENSE.txt
/README.txt
/robots.txt.dist
/web.config.txt
`
export default pattern;
