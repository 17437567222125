const pattern = `
# changes file
*.changes
*.chg

# system image
*.image
*.img7
*.img

# Pharo Smalltalk Debug log file
PharoDebug.log

# Squeak Smalltalk Debug log file
SqueakDebug.log

# Dolphin Smalltalk source file
*.sml

# Dolphin Smalltalk error file
*.errors

# Monticello package cache
/package-cache

# playground cache
/play-cache
/play-stash

# Metacello-github cache
/github-cache
github-*.zip
`
export default pattern;
