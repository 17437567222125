import { connect } from 'react-redux';
import ImageConversion from './ImageConversion';
import { convertFileAsync } from './imageConversionSlice';


const mapStateToProps = (state) => ({
    buttonName: state.imageconversion.imCommand,
    convertedImage: state.imageconversion.convertedImage,
    convertResize: state.imageconversion.convertResize,
    convertOutput: state.imageconversion.convertOutput

})

const mapDispatchToProps = (dispatch) => ({
    convertImage: (params) => dispatch(convertFileAsync(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageConversion);