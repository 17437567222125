export function getImageFileURL(imageFile) {
    if (imageFile === null || imageFile === undefined) {
      return '/assets/undraw_Photo_re_5blb.svg';
    }
    if (typeof imageFile === 'object' && imageFile.length > 0) {
      return URL.createObjectURL(imageFile[0]);
    }
    return '/assets/undraw_Photo_re_5blb.svg';
  }

export function getImageFile(imageFile) {
    if (imageFile === null || imageFile === undefined) {
      return null;
    }
    if (typeof imageFile === 'object' && imageFile.length > 0) {
      return imageFile[0];
    }
    return null;
  }