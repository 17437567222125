const pattern = `
# unpacked plugin folders
plugins/**/*

# files directory where uploads go
files

# DBMigrate plugin: generated SQL
db/sql

# AssetBundler plugin: generated bundles
javascripts/bundles
stylesheets/bundles
`
export default pattern;
