const pattern = `
rholog-*
sim-*
bin/libs
bin/RhoBundle
bin/tmp
bin/target
bin/*.ap_
*.o
*.jar
`
export default pattern;
