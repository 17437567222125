const pattern = `
*/config/development
*/logs/log-*.php
!*/logs/index.html
*/cache/*
!*/cache/index.html
!*/cache/.htaccess

user_guide_src/build/*
user_guide_src/cilexer/build/*
user_guide_src/cilexer/dist/*
user_guide_src/cilexer/pycilexer.egg-info/*

#codeigniter 3
application/logs/*
!application/logs/index.html
!application/logs/.htaccess
/vendor/
`
export default pattern;
