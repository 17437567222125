import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function AppNavBar() {
    return (
    <header>
        <Navbar bg="light" sticky="top">
            <Navbar.Brand href="#home">
                <img
                    src="/assets/logos/logo192.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="twitter-follow"
                />
                &nbsp; makeall.
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about">About</Nav.Link>
            </Nav>
            <Nav>
            <a target="_blank" rel="noreferrer" href="https://github.com/ranuzz/">
            <img
                src="/assets/logos/github.svg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="github-follow"
            />
            </a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/ranuzzzz">
            <img
                src="/assets/logos/twitter.svg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="twitter-follow"
            />
            </a>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
      </header>
    )
}
