const pattern = `
# Compiled Object files
*.o
*.obj

# Compiled Dynamic libraries
*.so
*.dylib
*.dll

# Compiled Static libraries
*.a
*.lib

# Executables
*.exe

# DUB
.dub
docs.json
__dummy.html
docs/

# Code coverage
*.lst
`
export default pattern;
