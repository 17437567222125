const pattern = `
# Built files
x86/
arm/
arm-p/
translations/*.qm

# IDE settings
.settings/
`
export default pattern;
