const pattern = `
# gitignore template for Vue.js projects
#
# Recommended template: Node.gitignore

# TODO: where does this rule come from?
docs/_book

# TODO: where does this rule come from?
test/
`
export default pattern;
