const pattern = `
# ignore the error log and .htaccess and others
error_log
.htaccess

# concrete5 5.6 specific

config/site.php
files/cache/*
files/tmp/*

# concrete5 5.7 specific

# ignore everything but the index.html
/application/files/*
!/application/files/index.html

# ignore updates folder
/updates/*

# ignore sitemap.xml
/sitemap.xml
`
export default pattern;
