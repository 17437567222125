const pattern = `
.vagrant
/cookbooks

# Bundler
bin/*
.bundle/*

.kitchen/
.kitchen.local.yml
`
export default pattern;
