const pattern = `
*/Files/binder.autosave
*/Files/binder.backup
*/Files/search.indexes
*/Files/user.lock
*/Files/Docs/docs.checksum
*/Files/Data/docs.checksum
*/QuickLook/
*/Settings/ui.plist
`
export default pattern;
