const pattern = `
# JPEG
*.jpg
*.jpeg
*.jpe
*.jif
*.jfif
*.jfi

# JPEG 2000
*.jp2
*.j2k
*.jpf
*.jpx
*.jpm
*.mj2

# JPEG XR
*.jxr
*.hdp
*.wdp

# Graphics Interchange Format
*.gif

# RAW
*.raw

# Web P
*.webp

# Portable Network Graphics
*.png

# Animated Portable Network Graphics
*.apng

# Multiple-image Network Graphics
*.mng

# Tagged Image File Format
*.tiff
*.tif

# Scalable Vector Graphics
*.svg
*.svgz

# Portable Document Format
*.pdf

# X BitMap
*.xbm

# BMP
*.bmp
*.dib

# ICO
*.ico

# 3D Images
*.3dm
*.max
`
export default pattern;
