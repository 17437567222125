const pattern = `
# gitignore template for Phoenix projects
# website: http://www.phoenixframework.org/
#
# Recommended template: Elixir.gitignore

# Temporary files
/tmp

# Static artifacts
/node_modules
/assets/node_modules

# Since we are building assets from web/static,
# we ignore priv/static. You may want to comment
# this depending on your deployment strategy.
/priv/static/

# Installer-related files
/installer/_build
/installer/tmp
/installer/doc
/installer/deps
`
export default pattern;
