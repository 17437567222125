const pattern = `
.eunit
*.o
*.beam
*.plt
erl_crash.dump
.concrete/DEV_MODE

# rebar 2.x
.rebar
rel/example_project
ebin/*.beam
deps

# rebar 3
.rebar3
_build/
_checkouts/
`
export default pattern;
