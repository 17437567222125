const pattern = `
# gitignore template for the Meteor framework
# website: https://www.meteor.com/
#
# Recommended template: Node.gitignore

# protect api keys in setting json
settings-production.json
settings.json

# protect your mup.json settings
mup.json
mup.js
`
export default pattern;
