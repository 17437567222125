const pattern = `
# Atari 2600 (Stella) support for multiple assemblers
# - DASM
# - CC65

# Assembled binaries and object directories
obj/
a.out
*.bin
*.a26

# Add in special Atari 7800-based binaries for good measure
*.a78
`
export default pattern;
