const pattern = `
# Calabash / Cucumber
rerun/
reports/
screenshots/
screenshot*.png
test-servers/

# bundler
.bundle
vendor
`
export default pattern;
