const pattern = `
# gitignore template for Drupal 7 projects
#
# It is recommended that you use Drupal.gitignore as this is the latest version

# Ignore configuration files that may contain sensitive information.
sites/*/*settings*.php
sites/example.sites.php

# Ignore paths that contain generated content.
files/
sites/*/files
sites/*/private
sites/*/translations

# Ignore default text files
robots.txt
/CHANGELOG.txt
/COPYRIGHT.txt
/INSTALL*.txt
/LICENSE.txt
/MAINTAINERS.txt
/UPGRADE.txt
/README.txt
sites/README.txt
sites/all/libraries/README.txt
sites/all/modules/README.txt
sites/all/themes/README.txt

# Ignore everything but the "sites" folder ( for non core developer )
.htaccess
web.config
authorize.php
cron.php
index.php
install.php
update.php
xmlrpc.php
/includes
/misc
/modules
/profiles
/scripts
/themes
`
export default pattern;
