const pattern = `
install/
log/
build/

# Ignore generated docs
*.dox
*.wikidoc

# eclipse stuff
.project
.cproject

# qcreator stuff
CMakeLists.txt.user

srv/_*.py
*.pcd
*.pyc
qtcreator-*
*.user

*~

# Emacs
.#*

# Colcon custom files
COLCON_IGNORE
AMENT_IGNORE
`
export default pattern;
