const pattern = `
# Cache objects
packer_cache/

# Crash log
crash.log

# For built boxes
*.box
`
export default pattern;
