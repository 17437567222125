const pattern = `
# gitignore template for Drupal 8 projects
#
# earlier versions of Drupal are tracked in community/PHP/

# Ignore configuration files that may contain sensitive information
/sites/*/*settings*.php
/sites/*/*services*.yml

# Ignore paths that may contain user-generated content
/sites/*/files
/sites/*/public
/sites/*/private
/sites/*/files-public
/sites/*/files-private

# Ignore paths that may contain temporary files
/sites/*/translations
/sites/*/tmp
/sites/*/cache

# Ignore testing related files
/sites/simpletest

# Ignore drupal core (if not versioning drupal sources)
/core
/vendor
/modules/README.txt
/profiles/README.txt
/themes/README.txt
/sites/README.txt
/sites/example.sites.php
/sites/example.settings.local.php
/sites/development.services.yml
/.csslintrc
/.editorconfig
/.eslintignore
/.eslintrc.json
/.gitattributes
/.ht.router.php
/.htaccess
/autoload.php
/example.gitignore
/index.php
/INSTALL.txt
/LICENSE.txt
/README.txt
/robots.txt
/update.php
/web.config
`
export default pattern;
