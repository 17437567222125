const pattern = `
# Cache, temp and personal files

/.htaccess
*.log

# Cache
/cache/*
!/cache/.htaccess
!/cache/cachefs/index.php
!/cache/deprecated.txt
!/cache/index.php
!/cache/purifier/index.php
!/cache/push/activity
!/cache/push/index.php
!/cache/push/trends
!/cache/sandbox/index.php
!/cache/smarty/cache/index.php
!/cache/smarty/compile/index.php
!/cache/smarty/index.php
!/cache/tcpdf/index.php

# Download
/download/*
!/download/.htaccess
!/download/index.php

# Images
/img/*
!/img/.htaccess
!/img/index.php
!/img/404.gif
!/img/bg_500.png
!/img/bg_loader.png
!/img/favicon.ico
!/img/loader.gif
!/img/loadingAnimation.gif
!/img/logo.jpg
!/img/logo.png
!/img/logo_invoice.jpg
!/img/logo_stores.png
!/img/macFFBgHack.png
!/img/prestashop-avatar.png
!/img/prestashop@2x.png
!/img/preston-login-wink@2x.png
!/img/preston-login@2x.png
!/img/questionmark.png
!/img/genders/index.php
!/img/admin/index.php
!/img/c/index.php
!/img/cms/index.php
!/img/co/index.php
!/img/jquery-ui
!/img/l/index.php
!/img/m/index.php
!/img/os/index.php
!/img/p/index.php
!/img/s/index.php
!/img/scenes
!/img/st/index.php
!/img/su/index.php
!/img/t/index.php
!/img/tmp/index.php

# Upload
/upload/*
!/upload/.htaccess

/vendor/*
/docs/phpdoc-sf/
/composer.lock
*.hot-update.js
*.hot-update.json


/admin-dev/autoupgrade/*
!/admin-dev/autoupgrade/index.php
!/admin-dev/autoupgrade/backup/index.php

/admin-dev/backups/*
!/admin-dev/backups/.htaccess

/admin-dev/import/*
!/admin-dev/import/.htaccess
!/admin-dev/import/index.php

/admin-dev/export/*
!/admin-dev/export/.htaccess
!/admin-dev/export/index.php

# Downloaded RTL files
/admin-dev/themes/default/css/bundle/default_rtl.css
/admin-dev/themes/default/css/bundle/shared_rtl.css
/admin-dev/themes/default/css/font_rtl.css
/admin-dev/themes/default/css/overrides_rtl.css
/admin-dev/themes/default/css/vendor/font-awesome/font-awesome_rtl.css
/admin-dev/themes/default/css/vendor/nv.d3_rtl.css
/admin-dev/themes/default/css/vendor/titatoggle-min_rtl.css
/admin-dev/themes/default/public/theme_rtl.css
/admin-dev/themes/new-theme/css/module/drop_rtl.css
/admin-dev/themes/new-theme/css/right-sidebar_rtl.css

themes/*/cache/*

# Config

config/settings.inc.php
config/settings.old.php
config/xml/*
config/themes/*
!config/xml/themes/default.xml
themes/*/config/settings_*.json
app/config/parameters.old.yml
app/config/config.php

# Themes, modules and overrides

modules/*
override/*
themes/*/
!themes/classic
!themes/_core
!themes/_libraries

# Vendors and dependencies

bower_components/
node_modules/
composer.phar
php-cs-fixer
.grunt/*

# Translations and emails templates

translations/*
mails/*
!mails/themes/
!mails/_partials/
themes/default-bootstrap/lang/*
themes/default-bootstrap/modules/*/translations/*.php
themes/default-bootstrap/mails/*
!themes/default-bootstrap/mails/en/
themes/default-bootstrap/modules/*/mails/*
!themes/default-bootstrap/modules/*/mails/en

# MISC

*sitemap.xml
/robots.txt

# Symfony

/bin/
/app/Resources/geoip/GeoLite2-City.mmdb
/app/Resources/translations/*
!/app/Resources/translations/default
/app/config/parameters.yml
/app/config/parameters.php
/build/
/phpunit.xml
/var/*
!/var/cache
/var/cache/*
!var/cache/.gitkeep
!/var/logs
/var/logs/*
!var/logs/.gitkeep
!/var/sessions
/var/sessions/*
!var/sessions/.gitkeep
!var/SymfonyRequirements.php
/vendor/
/web/bundles/

`
export default pattern;
