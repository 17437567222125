const pattern = `
# Compiled
*.elc

# Packaging
.cask

# Backup files
*~

# Undo-tree save-files
*.~undo-tree
`
export default pattern;
