const pattern = `
# gitignore template for Splunk apps
# documentation: http://docs.splunk.com/Documentation/Splunk/6.2.3/admin/Defaultmetaconf

# Splunk local meta file
local.meta

# Splunk local folder
local
`
export default pattern;
