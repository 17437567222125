import patterns from './patterns/index';

/**
 * Contains all gitignore patters
 * Categorised into Sections, Subsection and Pattern Object
 * Pattern Object:
 *  - Comment: can be None
 *  - Pattern: Single line pattern or None
 *  - patternFile: Pattern coming from an external file or None
 */
/*
let template = {
    "comment": null,
    "pattern": null,
    "patternFile": null,

}
*/
const allComponents = {
    "File/Folder" : {
        "Single File": {
            "comment" : "# single file",
            "pattern": "filename.extension",
            "patternFile": null,
        },
        "Single Folder": {
            "comment": "# single folder",
            "pattern": "foldername/",
            "patternFile": null,
        }
    },
    "Programming Language": {
        "CommonLisp": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.CommonLisp
        },
        "Python": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Python
        },
        "Ruby": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Ruby
        },
        "Rust": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Rust
        },
        "Cpp": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Cpp
        },
        "Elixir": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Elixir
        },
        "C" : {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.C
        },
        "Dart": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Dart
        },
        "Erlang" : {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Erlang
        }

    },
    "Framework" :{
        "Node": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Node
        },
        "Vue": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Vue
        },
        "Hugo": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Hugo
        },
        "WordPress" : {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Windows
        },
        "JupyterNotebooks": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.JupyterNotebooks
        }
    },
    "IDE": {
        "VisualStudioCode":  {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.VisualStudioCode,
        },
        "Unity": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Unity
        },
        "Vim": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Vim
        },
        "JetBrains": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.JetBrains
        },
        "Eclipse" : {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Eclipse
        }
    },
    "Operating System": {
        "macOS" : {
            "comment": "# macOS gitignore",
            "pattern": null,
            "patternFile": patterns.macOS
        },
        "Linux": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Linux
        },
        "Windows": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Windows
        },
        "Android": {
            "comment": null,
            "pattern": null,
            "patternFile": patterns.Android
        }
    }
};

allComponents["Everything"] = {};
for (const [key, value] of Object.entries(patterns)) {
    allComponents["Everything"][key] = {
        "comment": "",
        "pattern": null,
        "patternFile": value
    }
}
export default allComponents;