const pattern = `
**/IntegrationServer/datastore/
**/IntegrationServer/db/
**/IntegrationServer/DocumentStore/
**/IntegrationServer/lib/
**/IntegrationServer/logs/
**/IntegrationServer/replicate/
**/IntegrationServer/sdk/
**/IntegrationServer/support/
**/IntegrationServer/update/
**/IntegrationServer/userFtpRoot/
**/IntegrationServer/web/
**/IntegrationServer/WmRepository4/
**/IntegrationServer/XAStore/
**/IntegrationServer/packages/Wm*/
`
export default pattern;
