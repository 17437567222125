import { imageMagickTranscodeUploadedImage } from '../../services/imagemagick/imagemagickService';

export function convertFile(uploadedFile, command) {

    let outputUrl = imageMagickTranscodeUploadedImage(uploadedFile, command);
    if (outputUrl === null) {
      return new Promise((resolve) =>
          resolve({ data: '/assets/undraw_Photo_re_5blb.svg' })
        );
    }
    return new Promise((resolve) =>
      resolve({ data: outputUrl })
    );
  }
  