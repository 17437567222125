const pattern = `
# Ignore Play! working directory #
bin/
/db
.eclipse
/lib/
/logs/
/modules
/project/project
/project/target
/target
tmp/
test-result
server.pid
*.eml
/dist/
.cache
`
export default pattern;
