import { connect } from 'react-redux';
import { setConvertOutput, setConvertResize, setImCommand } from './imageConversionSlice';
import ImageMagickPanel from './ImageMagickPanel';

const mapStateToProps = (state) => ({
    buttonName: state.imageconversion.imCommand,
    convertResize: state.imageconversion.convertResize,
    convertOutput: state.imageconversion.convertOutput
})

const mapDispatchToProps = (dispatch) => ({
    setImCommand: (cmdname) => dispatch(setImCommand(cmdname)),
    setConvertResize: (newSize) => dispatch(setConvertResize(newSize)),
    setConvertOutput: (newOp) => dispatch(setConvertOutput(newOp))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageMagickPanel);