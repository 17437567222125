const pattern = `
.DS_Store
applet
application.linux-arm64
application.linux-armv6hf
application.linux32
application.linux64
application.windows32
application.windows64
application.macosx
out
`
export default pattern;
