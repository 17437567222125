import React, { Component } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import RangeSlider from 'react-bootstrap-range-slider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class ImageMagickPanel extends Component {

    render() {
        return (
            <>
            <Row style={{ border: '1px solid gray', borderRadius: '5px', marginLeft: '1px', marginRight: '1px', marginBottom: '10px', padding: '20px'}}>
                <Col xs={2} md={2} lg={2}></Col>
                <Col>
                <InputGroup className="mb-3">
                    <div key='inline-radio' className="mb-3">
                    <Form.Check
                        inline
                        label="Convert"
                        name="group1"
                        type='radio'
                        checked={this.props.buttonName === 'convert'}
                        onChange={() => this.props.setImCommand('convert')}
                        id='imagemagick-command-convert'
                    />
                    <Form.Check
                        inline
                        label="Mogrify"
                        name="group1"
                        type='radio'
                        checked={this.props.buttonName === 'morgify'}
                        onChange={() => this.props.setImCommand('morgify')}
                        id='imagemagick-command-mogrify'
                    />
                    <hr />
                    <p>Control panel for {this.props.buttonName} Command of Imagemagick</p>
                    {
                        this.props.buttonName === 'convert' ?
                        <>    
                                      
                            <RangeSlider
                                value={this.props.convertResize}
                                min={10}
                                max={500}
                                size='lg'
                                tooltipLabel={(v) => `Resize ${v} %`}
                                onChange={changeEvent => this.props.setConvertResize(changeEvent.target.value)}
                                />
                            <hr />
                            <p>Output Format</p>
                            <Form.Check
                                inline
                                label="jpeg"
                                name="group2"
                                type='radio'
                                checked={this.props.convertOutput === 'jpeg'}
                                onChange={() => this.props.setConvertOutput('jpeg')}
                                id='imagemagick-command-jpg'
                            />
                            <Form.Check
                                inline
                                label="png"
                                name="group2"
                                type='radio'
                                checked={this.props.convertOutput === 'png'}
                                onChange={() => this.props.setConvertOutput('png')}
                                id='imagemagick-command-png'
                            />
                        </>
                        :
                        <p>Morgify</p>
                    }
                    </div>
                </InputGroup>
                </Col>
                <Col xs={2} md={2} lg={2}></Col>
            </Row>
            </>
        );
    };
}