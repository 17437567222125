import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getImageFileURL } from './helper';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import ImageMagickPanelConnected from "./ImageMagickPanelConnected";

import styles from './ImageConversion.module.css';

function BrowserWarning() {
  return (
    <>
    <Row>
      <Col>
      <br />
      <Alert variant="dark">
        Works Only in Chrome Browser !
      </Alert>
      <hr />
      </Col>
    </Row>
    </>
  );
}

function ImagePanel(props) {

  return (
    <>
      <Card width="vw100" height="vh100" border="dark" bg="light" >
        <Card.Img variant="top" src={props.image} width="vw100" height="vh100" />
      </Card>
    </>
  );
  
}

function OutputImagePanel(props) {

  if (props.image === '/assets/undraw_Photo_re_5blb.svg') {
    return (
      <>
      <ImagePanel image={props.image}></ImagePanel>
      </>
    );
  } else {
    return (
      <>
      <ImagePanel image={props.image}></ImagePanel>
      <a href={props.image} download>Download Image</a>
      </>
    );
  }
}

export default class ImageConversion extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      imageFile: null
    };
  }

  render() {

    const imageFileValue = getImageFileURL(this.state.imageFile);
    return (
      <>
      <Container>
        <Row>
            <Col xs={2} md={2} lg={2}></Col>
            <Col>
            <br />
            <label>Choose an Image File to Convert</label>
                <input 
                  accept="image/*"
                  className="form-control form-control-lg"
                  type="file"
                  onChange={(e) => this.setState({ imageFile: e.target.files})}>
                  </input>
            <hr />
            </Col>
            <Col xs={2} md={2} lg={2}></Col>
        </Row>
        <ImageMagickPanelConnected></ImageMagickPanelConnected>
        <Row>
            <Col xs={2} md={4} lg={5}>
              <ImagePanel image={imageFileValue}></ImagePanel>
            </Col>
            <Col xs={2} md={4} lg={2}>
              {
                this.state.imageFile === null ? null :
                <Button className={styles.buttonimg} onClick={() => this.props.convertImage(
                  {
                    imageFile: this.state.imageFile,
                    imCommand: this.props.buttonName,
                    convertResize: this.props.convertResize,
                    convertOutput: this.props.convertOutput
                  })
                  }>
                    {this.props.buttonName}
                </Button>
              }       
            </Col>
            <Col xs={2} md={4} lg={5}>
              <OutputImagePanel image={this.props.convertedImage}></OutputImagePanel>
            </Col>
          </Row>
      </Container>
      </>
    );
  }

}
