const pattern = `
# gitignore template for the IAR EWARM
# website: https://www.iar.com/knowledge/support/technical-notes/ide/which-files-should-be-version-controlled/

# Some tools will put the EWARM files
# under a subdirectory with the same name
# as the configuration.
# Example
# EWARM/Config1/Obj /List /Exe
# EWARM/Config2/Obj /List /Exe
EWARM/**/Obj
EWARM/**/List
EWARM/**/Exe

# Autogenerated project files 
*.dep
*.ewt

# Autogenerated folder for debugger
EWARM/settings
`
export default pattern;
