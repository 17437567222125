const pattern = `
# For PCBs designed using Altium Designer
# Website: https://www.altium.com/altium-designer/

# Directories containing cache data
History
__Previews

# Directories containing logs and generated outputs
Project\\ Logs*
Project\\ Outputs*

# Misc files generated by altium
debug.log
Status\\ Report.txt
*.PcbDoc.htm
*.SchDocPreview
*.PcbDocPreview

# Lock files sometimes left behind
.~lock.*
`
export default pattern;
