const pattern = `
# the composer package lock file and install directory
# Commit your application's lock file http://getcomposer.org/doc/01-basic-usage.md#composer-lock-the-lock-file
# You may choose to ignore a library lock file http://getcomposer.org/doc/02-libraries.md#lock-file
# /composer.lock
/fuel/vendor

# the fuelphp document
/docs/

# you may install these packages with oil package.
# http://fuelphp.com/docs/packages/oil/package.html
# /fuel/packages/auth/
# /fuel/packages/email/
# /fuel/packages/oil/
# /fuel/packages/orm/
# /fuel/packages/parser/

# dynamically generated files
/fuel/app/logs/*/*/*
/fuel/app/cache/*/*
/fuel/app/config/crypt.php
`
export default pattern;
