const pattern = `
# DW Dreamweaver added files
_notes
_compareTemp
configs/
dwsync.xml
dw_php_codehinting.config
*.mno
`
export default pattern;
