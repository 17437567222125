const pattern = `
# gitignore template for Xilinx Vivado Design Suite
# website: https://www.xilinx.com/support/download.html

# [home]
*.jou
*.log
*.debug
*.str
*.zip
*.tmp
*.rst
*.os
*.js
*.pb
*.dcp
*.hwdef
*.vds
*.veo
*.wdf
*.vdi
*.dmp
*.rpx
*.rpt
*_stub.v
*_stub.vhdl
*_funcsim.v
*_funcsim.vhdl
.project

# [dir]
*.cache
.metadata
*.data
*.ipdefs
.Xil
*.sdk
*.hw
*.ip_user_files

### IP synth
*_synth_*

.jobs

### project synth
*/*.runs/synth*/*.xml
*/*.runs/synth*/*.txt
*/*.runs/synth*/*.sh
*/*.runs/synth*/*.tcl
*/*.runs/synth*/*.bat
*/*.runs/synth*/*.xdc
!*/*.runs/synth*/*utilization*.rpt

*.runs/synth*/*.xml
*.runs/synth*/*.txt
*.runs/synth*/*.sh
*.runs/synth*/*.tcl
*.runs/synth*/*.bat
*.runs/synth*/*.xdc
!*.runs/synth*/*utilization*.rpt

### project impl
*/*.runs/impl*/*.xml
*/*.runs/impl*/*.html
*/*.runs/impl*/*.txt
*/*.runs/impl*/*.sh
*/*.runs/impl*/*.tcl
*/*.runs/impl*/*.bat
!*/*.runs/impl*/*utilization*.rpt

*.runs/impl*/*.xml
*.runs/impl*/*.html
*.runs/impl*/*.txt
*.runs/impl*/*.sh
*.runs/impl*/*.tcl
*.runs/impl*/*.bat
!*.runs/impl*/*utilization*.rpt

### block design
*/*/bd/*/hdl
*/*/*/bd/*/hdl

*/*/bd/*/*.xdc
*/*/*/bd/*/*.xdc

*/*/bd/*/ip/*/*.xdc
*/*/*/bd/*/ip/*/*.xdc

*/*/bd/*/ip/*/*/
*/*/*/bd/*/ip/*/*/

*/*/bd/*/ip/*/*.vhd
*/*/*/bd/*/ip/*/*.vhd

*/*/bd/*/ip/*/*.xml
*/*/*/bd/*/ip/*/*.xml

*.c
*.h
*.vho
*.html
*/*/bd/*/ip/*/*.tcl
*/*/*/bd/*/ip/*/*.tcl
hw_handoff
ipshared
`
export default pattern;
