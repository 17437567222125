const pattern = `
# Dependencies
.psci_modules
.spago
bower_components
node_modules

# Generated files
.psci
output
`
export default pattern;
