const pattern = `
# Xojo (formerly REALbasic and Real Studio)

Builds*
*.debug
*.debug.app
Debug*.exe
Debug*/Debug*.exe
Debug*/Debug*\\ Libs
*.rbuistate
*.xojo_uistate
*.obsolete*
`
export default pattern;
