import { createSlice } from '@reduxjs/toolkit';
import debugLog from '../../services/debug/log';

const defaultEditorText = "Select a RuleSet to Edit";

const initialState = {
    value: 0,
    sectionSequence: 0,
    sections: [],
    curSequence: 0,
    editorItemId: -1,
    editorData: defaultEditorText,
};

export const generateGitignoreSlice = createSlice({
    name: 'generategitignore',
    initialState,
    reducers: {
        addSection: (state, action) => {
            state.sectionSequence += 1
            state.editorData = "";

            if (action.payload.content.comment !== null) {
                state.editorData += action.payload.content.comment + "\n";
            }
            if (action.payload.content.pattern != null) {
                state.editorData += action.payload.content.pattern + "\n";
            }
            if (action.payload.content.patternFile != null) {
                state.editorData += action.payload.content.patternFile + "\n";
            }
            state.editorItemId = state.sectionSequence;
            state.sections.push({
                "id": state.sectionSequence,
                "data": action.payload,
                "editorData": state.editorData
            });
        },
        deleteSection: (state, action) => {
            let inx = -1;
            debugLog.debug("deleteSection");
            debugLog.debug("deleteSection :: %o", action.payload);
            for (let i=0; i < state.sections.length; i++) {
                debugLog.debug("deleteSection :: cursection %o", state.sections[i]);
                if (state.sections[i]['id'] === parseInt(action.payload)) {
                    inx = i;
                    break;
                }
            }
            if (inx >= 0) {
                debugLog.debug("deleteSection :: deleting %d", inx);
                state.sections.splice(inx, 1);
                state.editorData = defaultEditorText;
                state.editorItemId = -1;
            }
        },
        editSection: (state, action) => {
            debugLog.debug(action);
            state.editorItemId = action.payload.id;
            state.editorData = action.payload.editorData;
        },
        updateSection: (state, action) => {
            debugLog.debug(action);
            let inx = -1;
            for (let i=0; i < state.sections.length; i++) {
                if (state.sections[i]['id'] === parseInt(state.editorItemId)) {
                    inx = i;
                    break;
                }
            }
            if (inx >= 0) {
                state.sections[inx].editorData = action.payload;
            }
        },
        previewOutput: (state, action) => {
            state.editorItemId = -1;
            state.editorData = "";
            for (let i=0; i < state.sections.length; i++) {
                state.editorData += state.sections[i]['editorData'] + "\n";
            }
        },
        downloadOutput: (state, action) => {
            state.editorItemId = -1;
            state.editorData = "";
            for (let i=0; i < state.sections.length; i++) {
                state.editorData += state.sections[i]['editorData'] + "\n";
            }
            const element = document.createElement("a");
            const file = new Blob([state.editorData], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = ".gitignore";
            document.body.appendChild(element);
            element.click();
        }
    }
});

export const { addSection, deleteSection, editSection, updateSection, previewOutput, downloadOutput } = generateGitignoreSlice.actions;

export const selectCount = (state) => state.generategitignore.value;
export const selectSections = (state) => state.generategitignore.sections;
export const selectEditorData = (state) => state.generategitignore.editorData;

export default generateGitignoreSlice.reducer;