import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function About() {
  return(
    <>
    <Container>
      <Row>
        <Col>
        <br />
        <header>
        <h1>About this</h1>
        <br />
        <p>Collection of micro-apps built alongside makeall.dev</p>
        
        <p>makeall.dev : Finished and Unifinished Projects</p>
        </header>
        <div>
        <br/><br/>
        <h2>Not so Frequently Asked Questions but Answered Anyways</h2>
        <br/><br/>
        <h3>What’s up with the name ?</h3>
        <p>The blog is named after the build automation tool <strong>make</strong> and aims to collect small projects, experiments and thoughts that didn’t get to see a production environment because of one reason or another.</p>
        <h3 id="where-is-the-code-">Where is the code ?</h3>
        <p>Most posts have link to the code that powers the widgets within. If not, please check this <a href="https://github.com/ranuzz/makeall-code">repository</a>.</p>
        <h3 id="do-posts-get-an-update-">Do posts get an update ?</h3>
        <p>Chronology of posts just represents the day I first wrote the draft and I do update them from time to time.</p>
        <h3 id="how-to-contact-the-author-">How to contact the author ?</h3>
        <p>If you find an error or have a suggestion feel free to send me an email <a href="mailto:ranuzz@makeall.dev">ranuzz@makeall.dev</a>.</p>
        <h3 id="how-to-get-updates-about-the-posts-">How to get updates about the posts ?</h3>
        <p>To stay updated follow me on <a href="https://github.com/ranuzz">github</a> and <a href="https://twitter.com/ranuzzzz">twitter</a> or signup for the newsletter.</p>

        <Button href="https://tinyletter.com/ranuzz" variant="secondary" size="lg">
          Subscribe for Updates
        </Button>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        </Col>
      </Row>
    </Container>
    </>
  );
}