const pattern = `
# gitginore template for creating Snap packages
# website: https://snapcraft.io/

parts/
prime/
stage/
*.snap

# Snapcraft global state tracking data(automatically generated)
# https://forum.snapcraft.io/t/location-to-save-global-state/768
/snap/.snapcraft/

# Source archive packed by snapcraft cleanbuild before pushing to the LXD container
/*_source.tar.bz2
`
export default pattern;
