const pattern = `
# Build and Release Folders
bin-debug/
bin-release/
[Oo]bj/
[Bb]in/

# Other files and folders
.settings/

# Executables
*.swf
*.air
*.ipa
*.apk

# Project files, i.e. .project, .actionScriptProperties and .flexProperties
# should NOT be excluded as they contain compiler settings and other important
# information for Eclipse / Flash Builder.
`
export default pattern;
