import Card from 'react-bootstrap/Card';

export default function GridTile(props) {
    return (
        <>
            <br /><br />
            <Card style={{ width: '22rem', cursor: 'pointer'}} border="dark" bg="light" onClick={() => {window.location = props.link;}}  >
              <Card.Img variant="top" src={props.image} width="200" height="200" />
              <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Subtitle>{props.subtitle}</Card.Subtitle>
              </Card.Body>
            </Card>
            <br /><br />
        </>
    );
}