const pattern = `
#User Specific
*.userprefs
*.usertasks

#Mono Project Files
*.pidb
*.resources
test-results/
`
export default pattern;
