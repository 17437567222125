const pattern = `
_build
_tracks

opa-debug-js

*.opp
*.opx
*.opx.broken
*.dump
*.api
*.api-txt
*.exe
*.log
`
export default pattern;
