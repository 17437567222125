import { call } from 'wasm-imagemagick';

export const imageMagickTranscodeUploadedImage = async(uploadedFile, command) => {
    const { name } = uploadedFile;
    
    const content = new Uint8Array(await uploadedFile.arrayBuffer());
    const image = { name: name, content }

    //const command = ["convert", name, '-rotate', '90', '-resize', '200%', 'out.png']
    const result = await call([image], command)

    // is there any errors ?
    if(result.exitCode !== 0) {
        console.log('There was an error: ' + result.stderr.join('\n'));
        return null;
    }
    // response can be multiple files (example split) here we know we just have one
    const outputImage = result.outputFiles[0]
    let format = command[command.length-1].split(".")[1];
    return URL.createObjectURL(new Blob([outputImage.buffer], {type: 'image/'+format}));
    
}
