import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './views/home.js';
import About from './views/about.js';
import AppNavBar from './components/navbar';
import Footer from './components/footer';
import { GenerateGitignore } from './features/gitignoregen/GenerateGitignore';
import ImageConversionConnected from './features/imageconversion/ImageConversionConnected';

function App() {
  return (
    <Router>
      <AppNavBar></AppNavBar>
        <Switch>
          <Route path="/imageconversion">
            <ImageConversionConnected />
          </Route>
          <Route path="/gitignoregen">
            <GenerateGitignore />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      <Footer></Footer>
    </Router>
  );
}

export default App;
