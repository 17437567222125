const pattern = `
# .gitignore for Grails 1.2 and 1.3
# Although this should work for most versions of grails, it is
# suggested that you use the "grails integrate-with --git" command
# to generate your .gitignore file.

# web application files
/web-app/WEB-INF/classes

# default HSQL database files for production mode
/prodDb.*

# general HSQL database files
*Db.properties
*Db.script

# logs
/stacktrace.log
/test/reports
/logs

# project release file
/*.war

# plugin release files
/*.zip
/plugin.xml

# older plugin install locations
/plugins
/web-app/plugins

# "temporary" build files
/target
`
export default pattern;
