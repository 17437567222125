const pattern = `
# SDCC stuff
*.lnk
*.lst
*.map
*.mem
*.rel
*.rst
*.sym
`
export default pattern;
