import { configureStore } from '@reduxjs/toolkit';
import imageConversionReducer from '../features/imageconversion/imageConversionSlice';
import generateGitignoreReducer from '../features/gitignoregen/GenerateGitignoreSlice';

export const store = configureStore({
  reducer: {
    "imageconversion": imageConversionReducer,
    "generategitignore": generateGitignoreReducer,
  },
});
