const pattern = `
# gitignore template for Nikola static site generator
# website: https://getnikola.com/

.doit.db
*.py[cod]
cache/
output/
`
export default pattern;
