const pattern = `
# gitignore for JBoss v4 projects

/server/all/data
/server/all/log
/server/all/tmp
/server/all/work
/server/default/data
/server/default/log
/server/default/tmp
/server/default/work
/server/minimal/data
/server/minimal/log
/server/minimal/tmp
/server/minimal/work

# Note:
# there may be other directories that contain *.xml.failed or *.war.failed files
/server/default/deploy/*.xml.failed
/server/default/deploy/*.war.failed
`
export default pattern;
