const pattern = `
*.ss~
*.ss#*
.#*.ss

*.scm~
*.scm#*
.#*.scm
`
export default pattern;
