import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getImageFile } from './helper';
import { convertFile } from './imageConversionAPI';


const initialState = {
    value: 0,
    status: 'idle',
    convertedImage: '/assets/undraw_Photo_re_5blb.svg',

    imCommand: 'convert',
    possibleCommands: ['convert', 'morgify'],
    convertResize: 10,
    convertOutput: 'jpeg',
    convertPossibleOutput: ['jpeg', 'png'],
};

export const convertFileAsync = createAsyncThunk(
    'imageconversion/convertFile',
    async (params) => {

      let imageFile = params.imageFile;
      let uploadedFile = getImageFile(imageFile);
      if (uploadedFile === null) {
        return '/assets/undraw_Photo_re_5blb.svg';
      }

      const { name } = uploadedFile;

      //const command = ["convert", "srcFile.png", '-rotate', '90', '-resize', '200%', 'out.png']
      let command = [];
      switch(params.imCommand) {
        case 'convert':
          command.push(params.imCommand)
          command.push(name)
          command.push('-rotate')
          command.push('0')
          command.push('-resize')
          command.push(params.convertResize + '%')
          command.push('out.'+params.convertOutput)
          break;
        case 'morgify':
          break;
        default:
          break;
      }

      const response = await convertFile(uploadedFile, command);
      return response.data;
    }
  );

export const imageConversionSlice = createSlice({
    name: 'imageconversion',
    initialState,
    reducers: {
        setImCommand: (state, action) => {
          let newCmd = action.payload;
          if (state.possibleCommands.includes(newCmd)) {
            state.imCommand = newCmd;
          }
        },
        setConvertResize: (state, action) => {
          let newSize = parseInt(action.payload);
          if (newSize > 500) {
            newSize = 500;
          }
          if (newSize < 10) {
            newSize = 10;
          }
          state.convertResize = newSize;
        },
        setConvertOutput: (state, action) => {
          let newOp = action.payload;
          if (state.convertPossibleOutput.includes(newOp)) {
            state.convertOutput = newOp;
          }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(convertFileAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(convertFileAsync.fulfilled, (state, action) => {
          state.status = 'idle';
          state.convertedImage = action.payload;
        })
        .addCase(convertFileAsync.rejected, (state, action) => {
          state.status = 'rejected';
          state.convertedImage = '/assets/undraw_Photo_re_5blb.svg';
        });
    }
});

export const { setImCommand, setConvertResize, setConvertOutput } = imageConversionSlice.actions;

export const selectConvertedImage = (state) => state.imageconversion.convertedImage;

export default imageConversionSlice.reducer;