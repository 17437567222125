const pattern = `
.htaccess
css.php
rpc/
sites/site*/admin/
sites/site*/private/
sites/site*/public/admin/
sites/site*/public/setup/
sites/site*/public/theme/
textpattern/
HISTORY.txt
README.txt
`
export default pattern;
